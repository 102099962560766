import React, {useEffect, useState} from 'react';
import LoanTable from '../../../components/PaisaiPaisa/common/GenericTable/loanTable';
import AdminHeader from '../../../components/PaisaiPaisa/common/adminHeader';
import AdminForm from '../../../components/PaisaiPaisa/common/adminForm';
import {Button, Form, message, Select, Tabs, Divider} from 'antd';
import axios from "axios";
import {getHeader} from '../../../methods/actions';
import sideBarItems from "../../../components/SideBar/sideBarItems";
import SideBarLayout from "../../../components/SideBar";

const {TabPane} = Tabs;

const LoanRate = () => {
    const [company, setCompany] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [response, setResponse] = useState(null);
    // const [postResult, setPostResult] = useState(null);
    const [loanType, setLoanType] = useState('auto');
    const [fixedAutoLoan, setFixedAutoLoan] = useState(null);
    const [variableAutoLoan, setVariableAutoLoan] = useState(null);
    const [fixedHomeLoan, setFixedHomeLoan] = useState(null);
    const [variableHomeLoan, setVariableHomeLoan] = useState(null);
    const [educationLoan, setEducationLoan] = useState(null);
    const [goldLoan, setGoldLoan] = useState(null);
    const [shareLoan, setShareLoan] = useState(null);
    const [dropDownData, setDropDownData] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [applicableFrom, setApplicableFrom] = useState(null);
    const header = getHeader();

    useEffect(() => {
        getBankList();
    }, []);

    const getBankList = async () => {
        try {
            const bankList = await axios.get(`${process.env.REACT_APP_SERVER_URL}/paisai-paisa/bank-lists`, header);
            setDropDownData(bankList.data.message);
            setInitialValues({company: bankList.data.message[0].ticker});
        } catch (e) {
            console.log(e)
        }
    }

    const callback = (key) => {
        console.log('key', key)
        setLoanType(key);
    };

    const onFinish = async (values) => {
        console.log("values",values);
        await getTableData(values.company);
        setCompany(values.company);
        setCompanyName(dropDownData.find(e => e.ticker === values.company).companyName);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed', errorInfo);
        message.error(errorInfo);
    }

    const MonthChange = (date, dateString,) => {
        // const others = {
        //     sector: 'CB',
        //     ticker: companyData ? companyData: company,
        //     companyName:dropDownData.find((element) => element.ticker === companyData ? companyData: company)?.companyName,
        //     ApplicableFrom: dateString,
        //     LoanType: loanType,
        // }
        // setOthers(others);
        setApplicableFrom(dateString);
    }

    const getTableData = async (company) => {

        try {
            const response = await axios.get(process.env.REACT_APP_SERVER_URL + `/paisai-paisa/loan-rates?ticker=${company}`, header);
            setCompany(company);
            setResponse(response);
            console.log('response', response);
            setFixedAutoLoan(response.data.message.auto.fixedAutoLoanList);
            setVariableAutoLoan(response.data.message.auto.variableAutoLoanList);
            setGoldLoan(response.data.message.gold);
            setVariableHomeLoan(response.data.message.home.variableHomeLoanList);
            setFixedHomeLoan(response.data.message.home.fixedHomeLoanList);
            setEducationLoan(response.data.message.education);
            setShareLoan(response.data.message.share);
        } catch (e) {
            console.log('e', e);
        }
    }

    const postData = async (result) => {

        try {
            const submitData = {
                formData: result,
                others: {
                    sector:'CB',
                    ticker:company,
                    companyName:companyName,
                    ApplicableFrom:applicableFrom,
                    LoanType:loanType,
                }
            }
            const postReponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/paisai-paisa/loan-rates/update`, submitData, header)
            if (postReponse.data.success) {
                message.success(postReponse.data.message);
            } else {
                message.error(postReponse.data.message);
            }
            console.log('postReponse', postReponse)
        } catch (e) {
            console.log(e);
            message.error(e.message);
        }
    }

    const items = [
        {
            label: 'AUTO', key: 'auto', children: <>
                <div>
                    <Divider>Variable Auto Loan</Divider>
                    <LoanTable tableData={variableAutoLoan} monthChange={MonthChange} postData={postData}
                               showBasedOn={false}/>
                </div>
                <div className={'mt-4'}>
                    <Divider>Fixed Auto Loan</Divider>
                    <LoanTable tableData={fixedAutoLoan} monthChange={MonthChange} postData={postData}/>
                </div>
            </>
        },
        {
            label: 'HOME', key: 'home', children: <>
                <div>
                    <Divider>Variable Home Loan</Divider>
                    <LoanTable tableData={variableHomeLoan} monthChange={MonthChange} postData={postData}
                               showBasedOn={false}/>
                </div>
                <div className={'mt-4'}>
                    <Divider>Variable Home Loan</Divider>
                    <LoanTable tableData={fixedHomeLoan} monthChange={MonthChange} postData={postData}/>
                </div>
            </>
        },
        {
            label: 'EDUCATION',
            key: 'edu',
            children: <LoanTable tableData={educationLoan} monthChange={MonthChange} postData={postData}/>
        },
        {
            label: 'GOLD',
            key: 'gold',
            children: <LoanTable tableData={goldLoan} monthChange={MonthChange} postData={postData}/>
        },
        {
            label: 'SHARE',
            key: 'share',
            children: <LoanTable tableData={shareLoan} monthChange={MonthChange} postData={postData}/>
        },
    ]

    return (
        <SideBarLayout siderItems={sideBarItems.paisaiPaisa}>
            <>
                <AdminHeader>
                    LOAN
                </AdminHeader>
                {Object.keys(initialValues).length !== 0 &&
                    <Form
                        layout='inline'
                        className={'mb-2 d-flex'}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={initialValues}
                    >
                        <div className={'d-flex mr-3'}>
                            <Form.Item label={'CHOOSE COMPANY'} className={'d-flex mr-5'}
                                       name={`company`}
                                       rules={[{required: true, message: 'Please select company'}]}
                            >
                                <Select
                                    showSearch
                                    style={{minWidth: 200}}
                                    optionFilterProp='children'
                                    // onChange={industryChange}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {dropDownData.map((item, index) =>
                                        (
                                            <Select.Option key={index}
                                                           value={item.ticker}>{item.companyName}</Select.Option>
                                        ),
                                    )}
                                </Select>
                            </Form.Item>
                        </div>
                        <Form.Item>
                            <Button htmlType={'submit'}
                                    style={{
                                        background: '#FFCE00',
                                        paddingInline: '20px',
                                        fontWeight: 600
                                    }}>VIEW</Button>
                        </Form.Item>
                    </Form>
                }
                {response &&
                    <Tabs defaultActiveKey={'1'} onChange={callback} items={items}/>
                }
            </>
        </SideBarLayout>
    );
};

export default LoanRate;
