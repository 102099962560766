import {Table, Select, Button, InputNumber, DatePicker, message} from 'antd';
import '../style.css';
import {useEffect, useRef, useState} from 'react';

const {MonthPicker} = DatePicker;

const FixedDepositTable = ({tableData, monthChange, postData}) => {

    const [dataSource, setDataSource] = useState([]);
    const [selectedDate, setSelectedDate] = useState(false);
    const [allInputData, setAllInputData] = useState(null);
    const dateRef = useRef(null);

    useEffect(() => {
        setDataSource(tableData);
    }, [tableData]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'Name',
            key: 'Name',
            // sorter: (a, b) => a.Name.localeCompare(b.Name),
        }, {
            title: 'Term',
            dataIndex: 'term',
            key: 'term',
            // sorter: (a, b) => a.term.localeCompare(b.term),
        }, {
            title: 'Interest Rate',
            dataIndex: 'interestRate',
            key: 'interestRate',
            render: (text, record) => (
                    <InputNumber value={text} onChange={(value) => onInputChange(value, record)}/>
            ),
        },
    ];

    const onInputChange = (value, record) => {
        console.log('record', record);
        const newData = [...dataSource];
        const updatedRecord = {...record, interestRate: Number(value)};
        newData.splice(record.key, 1, updatedRecord);
        setDataSource(newData);
    }

    const onSubmit = () => {
        if (selectedDate) {
            postData(dataSource);
        } else {
            message.error('Please select date');
            dateRef.current.focus();
        }
    };

    const dateSelected = (date, dateString) => {
        monthChange(date, dateString);
        setSelectedDate(true)
    }

    const onAllInputChange = (value) => {
        console.log('value', value);
        setAllInputData(value);
    }

    const fillAllInputValue = () => {
        console.log('inside button dataSource', dataSource);
        const newData = [...dataSource];
        newData.forEach(item => {
            item.interestRate = allInputData;
        })
        setDataSource(newData);
    }

    return (
        <>
            <div>
                <InputNumber placeholder="Add to all" onChange={onAllInputChange}/>
                <Button type={'primary'} onClick={fillAllInputValue}>Add to All</Button>
            </div>
            <Table dataSource={dataSource} columns={columns} scroll={{y: 450}} pagination={false}/>
            <div className={'d-flex align-items-baseline justify-content-end'}>
                <p className={'mx-3 fw-bold'}>
                    NEW DATA APPLICABLE FOR
                </p>
                <MonthPicker onChange={dateSelected} ref={dateRef}/>
            </div>
            <div className='d-flex justify-content-end' style={{marginTop: '2rem'}}>
                <Button onClick={onSubmit} type={'primary'} style={{background: '#2D3648'}}> Save</Button>
            </div>
        </>
    );
};

export default FixedDepositTable;
