import PaisaiPaisaAdmin from "../containers/paisaipaisa/PaisaiPaisa";
import LoanRate from "../containers/paisaipaisa/LoanRate/LoanRate";
import BaseRate from "../containers/paisaipaisa/BaseRate";
import SavingRate from "../containers/paisaipaisa/SavingRate";
import FixedDeposit from "../containers/paisaipaisa/FixedDeposit";
import PaisaiPaisaBlog from "../containers/paisaipaisa/Blogs";

const paisaipaisaRouter = [
    {
        title: 'Paisai Paisa',
        component: PaisaiPaisaAdmin,
        url: '/paisai-paisa',

    },
    {
        title: "Loan Rate",
        component: LoanRate,
        url: '/paisai-paisa/loan-rate'
    },
    {
        title: "Base Rate",
        component: BaseRate,
        url:'/paisai-paisa/base-rate'
    },
    {
        title: 'Saving Rate',
        component:SavingRate,
        url:'/paisai-paisa/saving-rate'
    },
    {
        title: 'Fixed Deposit',
        component:FixedDeposit,
        url:'/paisai-paisa/fixed-deposit'
    },{
        title: 'Paisai Paisa Blogs',
        component:PaisaiPaisaBlog,
        url:'/paisai-paisa/blogs'
    },
];

export default paisaipaisaRouter;