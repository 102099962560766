import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, message} from "antd";

import axiosOrder from "../../../axios-order";
import CommonAddModalBox from "./Common/CommonAddModalBox";

const EditCompanyInfo = ({form, reloadData, isLoading}) => {
    const [companyInfoForm] = Form.useForm();

    const onEditSubmit = async (values) => {
        try {
            const finalValues = {...values, Company: form.Company}
            const {data: {code, message: restMessage}} = await axiosOrder.post("/company-info/update", finalValues, {
                params: {
                    Identifier: values.Identifier
                }
            });
            if (code === 200) {
                message.success(restMessage);
                companyInfoForm.resetFields();
                await reloadData();
            } else throw new Error();
        } catch (e) {
            console.log(e);
            message.warning("Cannot update company identifier");
        }
    };

    useEffect(() => {
        let formattedData = {};
        Object.keys(form).forEach(item => {
            formattedData[item] = form[item] !== '-' ? form[item] : undefined;
        })
        companyInfoForm.setFieldsValue(formattedData);
        return () => {
            companyInfoForm.resetFields()
        };
    }, [form]);

    return (
        <div>
            <Form form={companyInfoForm} title={"Edit Company Info"} layout={'vertical'} onFinish={onEditSubmit}>
                <CommonAddModalBox edit={true} isLoading={isLoading}/>
                <Button type={'primary'} htmlType={'submit'}>Update</Button>
            </Form>
        </div>
    );
};

EditCompanyInfo.propTypes = {
    form: PropTypes.shape({
        Qualification: PropTypes.string,
        Name: PropTypes.string,
        Tkr: PropTypes.string,
        Position: PropTypes.string,
        Identifier: PropTypes.string,
        Type: PropTypes.string,
        Year: PropTypes.string,
        Company: PropTypes.string,
    }),
    reloadData: PropTypes.func.isRequired,

};

export default EditCompanyInfo;
