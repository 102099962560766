import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/PaisaiPaisa/common/GenericTable";
import AdminHeader from "../../../components/PaisaiPaisa/common/adminHeader";
import AdminForm from "../../../components/PaisaiPaisa/common/adminForm";
import { Button, DatePicker, Form, Input, message, Select, Table } from "antd";
import { getHeader } from "../../../methods/actions";
import axios from "axios";
import BaseRateTable from "../../../components/PaisaiPaisa/common/GenericTable/BaseRateTable";
import sideBarItems from "../../../components/SideBar/sideBarItems";
import SideBarLayout from "../../../components/SideBar";

const { MonthPicker } = DatePicker;

const BaseRate = () => {
  const [tableData, setTableData] = useState([]);
  const [Industry, setIndustry] = useState(null);
  const [ApplicableFrom, setApplicableFrom] = useState(null);
  const [response, setResponse] = useState(null);
  const [paramDate, setParamDate] = useState(null);
  const dropDown = {
    Industry: ["CB"],
  };
  const header = getHeader();

  const postData = async (result) => {
    const submitData = {
      formData: result,
      others: response.others,
    };
    // console.log('submitData', submitData)
    try {
      const postReponse = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/paisai-paisa/base-rates/update`,
        submitData,
        header
      );
      console.log("postReponse", postReponse);
      if (postReponse.data.success) {
        message.success(postReponse.data.message);
      } else {
        message.error(postReponse.data.message);
      }
    } catch (e) {
      console.log(e);
      message.error(e);
    }
  };
  const options = {
    editable: {
      input: {
        columns: ["BaseRate"],
      },
      select: {
        columns: ["ApplicableBaseRateSettings"],
        option: [
          {
            value: "3 Months Average",
            label: "3 Months Average",
          },
          {
            value: "Latest",
            label: "Latest",
          },
        ],
      },
    },
  };

  const getTableData = async (Industry) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          `/paisai-paisa/base-rates?Industry=${Industry}&ApplicableFrom=${paramDate}`,
        header
      );
      console.log("Response", response);
      if (response.data.code === 200) {
        // const formData = response.data.message.map((item, index) => {
        //     item.key = index
        //     return item;
        // })
        const result = {
          formData: response.data.message,
          others: {
            Industry: Industry,
            ApplicableFrom: paramDate,
          },
        };
        setResponse(result);
        setTableData(response.data.message);
      }
    } catch (e) {
      console.log("e", e);
    }
  };

  const MonthChange = (date, dateString) => {
    setParamDate(dateString);
  };

  const onFinish = async (values) => {
    await getTableData(values.Industry);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed", errorInfo);
  };

  const dropDownLabel = Object.keys(dropDown)[0];
  const dropDownOptions = Object.values(dropDown)[0];

  const initialValues = {
    [dropDownLabel]: dropDownOptions[0],
  };

  return (
    <SideBarLayout siderItems={sideBarItems.paisaiPaisa}>
      <div>
        <AdminHeader>Base Rate</AdminHeader>
      </div>
      <Form
        layout="inline"
        className={"mb-2 d-flex"}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={initialValues}
      >
        <div className={"d-flex mr-3"}>
          <Form.Item
            label={dropDownLabel.toUpperCase()}
            className={"d-flex mr-5"}
            name={`${dropDownLabel}`}
            rules={[{ required: true, message: "Please select industry" }]}
          >
            <Select
              showSearch
              style={{ minWidth: 200 }}
              optionFilterProp="children"
              // onChange={industryChange}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {dropDownOptions.map((item, index) => (
                <Select.Option key={index} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={"DATE"}
            className={"d-flex "}
            name="ApplicableFrom"
            rules={[{ required: true, message: "Please select Date" }]}
          >
            <MonthPicker name={"monthpicker"} onChange={MonthChange} />
          </Form.Item>
        </div>
        <Form.Item>
          <Button
            htmlType={"submit"}
            style={{
              background: "#FFCE00",
              paddingInline: "20px",
              fontWeight: 600,
            }}
          >
            VIEW
          </Button>
        </Form.Item>
      </Form>
      {
        tableData.length !== 0 && (
          <CustomTable
            datas={tableData}
            options={options}
            postData={postData}
          />
        )
        // <Table columns={columns} dataSource={tableData}/>
      }
    </SideBarLayout>
  );
};

export default BaseRate;
