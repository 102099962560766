import SideBarLayout from "../../components/SideBar";
import sideBarItems from "../../components/SideBar/sideBarItems";

const PaisaiPaisaAdmin = () => {
    return (
        <SideBarLayout siderItems={sideBarItems.paisaiPaisa}>
            test
        </SideBarLayout>
    );
};

export default PaisaiPaisaAdmin;
