import {
  Form,
  Input,
  Button,
  Modal,
  Checkbox,
  Select,
  InputNumber,
} from "antd";

import { useState } from "react";

import CustomInputField from "./CustomInputField";

import "antd/dist/antd.css";
import ProcessVariableInputField from "./ProcessVariableInputField";

const { useForm } = Form;

const FeatureFlagForm = () => {
  const [eventMainModelVisible, setEventMainModelVisible] = useState(false);
  const [userTaskMainModelVisible, setUserTaskMainModelVisible] =
    useState(false);
  const [form] = Form.useForm();
  const [activity, setActivity] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState({});
  const [customEventProps, setCustomEventProps] = useState({});
  const [customUserTaskProps, setCustomUserTaskProps] = useState({});
  const [processVariablesInput, setProcessVariablesInput] = useState([]);
  let containerForPvi = [];

  const onFinish = (values) => {
    const { Events, UserTasks } = checkboxValues;

    // const eventModel = {};
    // const userTaskModel = {};

    // if (values.eventBoid) {
    //   eventModel.boid = values.eventBoid;
    // }

    // if (values.eventClientName) {
    //   eventModel.clientName = values.eventClientName;
    // }

    // if (values.userTasksIsBoDetailChangeApprovedAgentInit) {
    //   userTaskModel.isBoDetailChangeApprovedAgentInit =
    //     values.userTasksIsBoDetailChangeApprovedAgentInit;
    // }

    // if (values.userTasksIsBoDetailChangeApprovedAgentInitComment) {
    //   userTaskModel.isBoDetailChangeApprovedAgentInitComment =
    //     values.userTasksIsBoDetailChangeApprovedAgentInitComment;
    // }

    const event_object =
      values.eventType ||
      values.eventUrl ||
      values.eventName ||
      values.eventSubType
        ? {
            events: [
              {
                ...(values.eventUrl ? { url: values.eventUrl } : {}),
                ...(values.eventName ? { name: values.eventName } : {}),
                ...(values.eventType ? { type: values.eventType } : {}),

                model: customEventProps,

                ...(values.eventSubType
                  ? { subType: values.eventSubType }
                  : {}),
              },
            ],
          }
        : null;

    const userTask_object =
      values.userTasksUrl || values.userTasksName || values.userTasksUrla
        ? {
            userTasks: [
              {
                ...(values.userTasksUrl ? { url: values.userTasksUrl } : {}),
                ...(values.userTasksName ? { name: values.userTasksName } : {}),
                ...(values.userTasksUrla ? { urla: values.userTasksUrla } : {}),
                model: customUserTaskProps,
              },
            ],
          }
        : null;
    const result = {
      name: values.name,
      type: values.type,
      group: values.group,
      activity: {
        ...(event_object ? event_object : {}),
        ...(userTask_object ? userTask_object : {}),
      },
      businessKeyDisplayName: values.businessKeyDisplayName,
      processVariables: containerForPvi,
      groupOrder: values.groupOrder,
      businessKey: values.businessKey,
      displayName: values.displayName,
    };

    if (Events && UserTasks) {
      console.log("Form submitted with values:", values);
      console.log("result--", JSON.stringify(result));
    } else {
      alert("Please check both checkboxes before submitting.");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // const showEventMainModal = () => {
  //   setEventMainModelVisible(true);
  // };
  // const showUserTaskMainModal = () => {
  //   setUserTaskMainModelVisible(true);
  // };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    form.setFieldsValue({ [name]: value });
    setActivity(value);
    setCheckboxValues({
      ...checkboxValues,
      [value]: checked,
    });

    if (value === "Events") {
      setEventMainModelVisible(checked);
    } else if (value === "UserTasks") {
      setUserTaskMainModelVisible(checked);
    } else {
      setEventMainModelVisible(false);
      setUserTaskMainModelVisible(false);
    }
  };

  const handleChange = (val) => {};

  const options = [
    { label: "BOID", value: "BOID" },
    { label: "Client Name", value: "Client Name" },
    { label: "Ref No", value: "Ref No" },
    {
      label: "MessengerProcessInstanceId",
      value: "MessengerProcessInstanceId",
    },
    {
      label: "MessengerProcessDefKey",
      value: "MessengerProcessDefKey",
    },
    {
      label: "MessengerRefNo",
      value: "MessengerRefNo",
    },
    {
      label: "CitizenshipNo",
      value: "CitizenshipNo",
    },
    {
      label: "PhoneNo",
      value: "PhoneNo",
    },
    {
      label: "Email",
      value: "Email",
    },
  ];

  const returnEventInputObj = (val) => {
    setCustomEventProps(val);
  };

  const returnUserTaskInputObj = (val) => {
    setCustomUserTaskProps(val);
  };

  const returnProcessVariables = (val) => {
    setProcessVariablesInput(val);
    containerForPvi = processVariablesInput.map((val) => {
      return { name: val.name, displayName: val.displayName };
    });
  };

  return (
    <Form
      name="processForm"
      form={form}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
      }}
      onFinish={onFinish} // Handle form submission success
      onFinishFailed={onFinishFailed} // Handle form submission failure
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: "Please input name",
          },
        ]}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label="Type"
        name="type"
        rules={[
          {
            required: true,
            message: "Please enter the type",
          },
        ]}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label="Group"
        name="group"
        rules={[
          {
            required: true,
            message: "Please enter the group",
          },
        ]}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        name="activity"
        label="Activity"
        rules={[
          {
            required: true,
            message: "Please enter the type",
            validator: (_, value) => {
              if (Object.values(checkboxValues).some((value) => value)) {
                return Promise.resolve();
              }
              return Promise.reject(
                "Please check all checkboxes before submitting."
              );
            },
          },
        ]}
      >
        <Checkbox.Group style={{ width: "100%" }}>
          <Checkbox
            name="activity"
            value="Events"
            onChange={handleCheckboxChange}
          >
            Events
          </Checkbox>
          <Checkbox
            name="activity"
            value="UserTasks"
            onChange={handleCheckboxChange}
          >
            User Tasks
          </Checkbox>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item>
        <Modal
          title="Events"
          open={eventMainModelVisible}
          onOk={() => setEventMainModelVisible(false)}
          onCancel={() => setEventMainModelVisible(false)}
          name="events"
        >
          <Form.Item name="eventUrl" id="url" label="Url">
            <Input type="text" />
          </Form.Item>
          <Form.Item name="eventName" id="eventName" label="Name">
            <Input type="text" />
          </Form.Item>
          <Form.Item name="eventType" id="eventType" label="Type">
            <Input type="text" />
          </Form.Item>
          <Form.Item name="eventSubType" id="eventSubType" label="SubType">
            <Input type="text" />
          </Form.Item>
          <p>Modal</p>
          <CustomInputField returnEventInputObj={returnEventInputObj} />
          {/* <Form.Item name="eventBoid" id="eventBoid" label="Boid">
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="eventClientName"
            id="eventClientName"
            label="ClientName"
          >
            <Input type="text" />
          </Form.Item> */}
        </Modal>
      </Form.Item>

      <Form.Item>
        <Modal
          title="UserTasks"
          open={userTaskMainModelVisible}
          onOk={() => setUserTaskMainModelVisible(false)}
          onCancel={() => setUserTaskMainModelVisible(false)}
          name="userTasks"
        >
          <Form.Item name="userTasksUrl" id="userTasksurl" label="Url">
            <Input type="text" />
          </Form.Item>
          <Form.Item name="userTasksName" id="userTasksName" label="Name">
            <Input type="text" />
          </Form.Item>
          <CustomInputField returnUserTaskInputObj={returnUserTaskInputObj} />

          {/* <Form.Item
            name="userTasksIsBoDetailChangeApprovedAgentInit"
            id="userTasksIsBoDetailChangeApprovedAgentInit"
            label="isBoDetailChangeApprovedAgentInit"
          >
            <Input type="boolean" />
          </Form.Item>
          <Form.Item
            name="userTasksIsBoDetailChangeApprovedAgentInitComment"
            id="userTasksIsBoDetailChangeApprovedAgentInitComment"
            label="isBoDetailChangeApprovedAgentInitComment"
          >
            <Input type="text" />
          </Form.Item> */}
        </Modal>
      </Form.Item>

      <Form.Item
        label="Group Order"
        name="groupOrder"
        rules={[
          {
            required: true,
            message: "Please enter the group order",
          },
        ]}
      >
        <InputNumber min={0} />
      </Form.Item>

      <Form.Item
        label="Business Key"
        name="businessKey"
        rules={[
          {
            required: true,
            message: "Please enter the business key",
          },
        ]}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label="Display Name"
        name="displayName"
        rules={[
          {
            required: true,
            message: "Please enter the display name",
          },
        ]}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label="Business Key DisplayName"
        name="businessKeyDisplayName"
        rules={[
          {
            required: true,
            message: "Please enter the display name",
          },
        ]}
      >
        <Select
          mode="multiple"
          style={{
            width: "100%",
          }}
          placeholder="Please select"
          onChange={handleChange}
          options={options}
        />
      </Form.Item>

      <Form.Item label="processVariables">
        <ProcessVariableInputField
          returnProcessVariables={returnProcessVariables}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FeatureFlagForm;
