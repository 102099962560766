import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { MdArrowDropDown } from "react-icons/md";
import FormControl from "@material-ui/core/FormControl";
import { Select, Dropdown, Space, Menu } from "antd";
import MaterialSideDrawer from "./MaterialSideDrawer";

const { Option } = Select;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    formControl: {
      backgroundColor: "white",
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

// export default
function MaterialAppBar(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  let history = useHistory();
  const [state, setState] = useState({ left: false });

  const featureFlagDropDown = () => {
    const menu = (
      <Menu>
        <Menu.Item>
          <a
            rel="noopener noreferrer"
            href="/feature-flag/flowvity/admin-panel"
          >
            Flowvity Admin Panel
          </a>
        </Menu.Item>
        <Menu.Item>
          <a rel="noopener noreferrer" href="/feature-flag/flowvity/bpmn-panel">
            Flowvity Website Features
          </a>
        </Menu.Item>
        <Menu.Item>
          <a rel="noopener noreferrer" href="/feature-flag/flowvity/bpmn-panel">
            Flowvity BPMN Panel
          </a>
        </Menu.Item>
        <Menu.Item>
          <a href="/feature-flag/flowvity/bpmn-panel">Flowvity App Panel</a>
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu}>
        <Button
          onClick={(e) => {
            e.preventDefault();
            history.push("/feature-flag/flowvity");
          }}
          color="inherit"
        >
          FEATURE FLAG SYSTEM <MdArrowDropDown />
        </Button>
      </Dropdown>
    );
  };

  const nepseDropDown = () => {
    const menu = (
      <Menu>
        <Menu.Item>
          <a rel="noopener noreferrer" href="/addUpdate">
            Add Updates
          </a>
        </Menu.Item>
        <Menu.Item>
          <a rel="noopener noreferrer" href="/manualupdate">
            Update Clients
          </a>
        </Menu.Item>
        <Menu.Item>
          <a rel="noopener noreferrer" href="/companyInfo">
            View Company Info
          </a>
        </Menu.Item>
        <Menu.Item>
          <a href="/logs">View Logs</a>
        </Menu.Item>
        <Menu.SubMenu title="Mutual Fund Operation">
          <Menu.Item>
            {" "}
            <a rel="noopener noreferrer" href="/historicalNav">
              Add Historical Net Asset Value
            </a>
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    );

    return (
      <Dropdown overlay={menu}>
        <Button onClick={(e) => e.preventDefault()} color="inherit">
          NEPSE <MdArrowDropDown />
        </Button>
      </Dropdown>
    );
  };

  const onOptionChangeHandler = (value) => {
    // setdatabase(event.target.value);
    // console.log(location.pathname);
    dispatch({ type: "SET_DATABASE", payload: value });
    props.storedatabase(value);
  };

  const onPaisaiPaisaClickHandler = (e) => {
    e.preventDefault();
    history.push(`/paisai-paisa/base-rate`);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <MaterialSideDrawer state={state} toggleDrawer={toggleDrawer} />
        <Toolbar>
          <Fragment>
            <IconButton
              edge="start"
              onClick={toggleDrawer("left", true)}
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title} color="inherit">
              SMTM Capital
            </Typography>

            <Fragment>
              {nepseDropDown()}
              {featureFlagDropDown()}
              <Button
                value="paisai-paisa"
                onClick={onPaisaiPaisaClickHandler}
                color="inherit"
              >
                Paisai Paisa
              </Button>
              {/* <Button
                value="manualupdate"
                onClick={onViewUserNavButtonclickHandler}
                color="inherit"
              >
                View Users
              </Button> */}
              {/* <Button
                value="companyInfo"
                onClick={onviewCompanyInfo}
                color="inherit"
              >
                View Company Info
              </Button> */}
              {/* <Button
                name="logbutton"
                onClick={onViewLogsNavButtonclickHandler}
                color="inherit"
                value="updatelogs"
              >
                View Logs
              </Button> */}
              {/* <Button
                name="templateButton"
                onClick={template}
                color="inherit"
                value="addUpdate"
              >
                Update Template
              </Button> */}
              {/* <Button
                name="nepseButton"
                // onClick={template}
                color="inherit"
                value="nepse"
                onClick={nepseDropDownVisibilityToggle}
              >
                NEPSE
                <FontAwesomeIcon
                  icon={faCaretDown}
                  style={{ color: "#FFFFFF" }}
                  onClick={nepseDropDownVisibilityToggle}
                />
              </Button>
              {nepseDropDownVisibility && nepseDropDown()} */}

              <FormControl className={classes.formControl}>
                {/* <InputLabel htmlFor="age-native-simple">APP</InputLabel> */}
                <Select value={props.database} onChange={onOptionChangeHandler}>
                  <Option name="npstocks" value="npstocks">
                    npstocks
                  </Option>
                  <Option name="systemxlite" value="systemxlite">
                    systemxlite
                  </Option>
                  <Option name="systemx" value="systemx">
                    systemx
                  </Option>
                </Select>
              </FormControl>
            </Fragment>
          </Fragment>
        </Toolbar>
      </AppBar>
    </div>
  );
}
const mapStateToProps = (state) => ({
  database: state.database,
});
const mapDispatchToProps = (dispatch) => {
  return {
    storedatabase: (database) => {
      dispatch({ type: "SET_DATABASE", payload: database });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MaterialAppBar);
