import React, {useMemo} from 'react';
import classNames from "classnames";
import {Button, Input, Select, AutoComplete} from "antd";
import {CloseCircleOutlined} from "@ant-design/icons";
import "./index.css";

import {EditableItemProps} from "./Types";

const EditableItem = React.memo(({
                                     value,
                                     onDelete,
                                     onAdd,
                                     onChange,
                                     stockList,
                                     index,
                                     list,
                                     deletable,
                                     bordered,
                                 }: EditableItemProps) => {

    const onDeleteItem = (key) => {
        onDelete(key);
    }
    const onTextChange = (text, index, inputName: string) => {
        onChange(text, index, inputName);
    };

    const addNewInput = (event: any, text: any, index: number) => {
        event.preventDefault();
        // console.log(list.findIndex(item => item.trim().length !== 0));
        const hasNotEmptyInput = list.every(item => item.AffiliatedCompany.trim().length !== 0);
        if (event.key === 'Enter' && text.AffiliatedCompany.trim().length !== 0 && hasNotEmptyInput) {
            onAdd();
        }
    }

    const stockListMemo = useMemo(() => {
        return stockList
    }, [stockList]);


    return (
        <div className={classNames('editable-item', {'item-border': bordered})}>
            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px'}}>
                <div>
                    <p className={'input-p'}>Affiliated Company</p>
                    <AutoComplete style={{width: '100%'}}
                                  placeholder={"Press Enter To Add More Data"}
                                  options={stockListMemo.map(item => {
                                      return {value: item.StockName}
                                  })}
                                  value={value.AffiliatedCompany === "" ? undefined : value.AffiliatedCompany}
                                  onChange={(value) => onTextChange(value, index, 'AffiliatedCompany')}
                                  onKeyUp={(event,) => addNewInput(event, value, index)}

                    />
                </div>
                <div>
                    <p className={'input-p'}>Position</p>
                    <Input placeholder={"Enter Position"}
                           onKeyUp={(event,) => addNewInput(event, value, index)}
                           value={value.Position}
                           onChange={(e) => onTextChange(e.target.value, index, 'Position')}/>
                </div>
            </div>
            <div className={'delete-container'}>
                {deletable && <Button title={'Delete'} danger icon={<CloseCircleOutlined/>}
                                      onClick={() => onDeleteItem(index)}/>}
            </div>
        </div>
    );
});

export default EditableItem;
