import { React } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import BootstrapModal from './BootstrapModal';
import SystemXUpdateUserModel from './SystemXUpdateUserModel'
import ResetSystemxUserDialogueBox from './ResetSystemxUserDialogueBox'


const useStyles2 = makeStyles({
  table: {
    // minWidth: 500,
  },
});

function MaterialSystemXUserDetailTable(props) {
  const classes = useStyles2();
  const { rowsPerPage, rows, page, database, fetchAllDataByOption } = props;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center"><b>SN</b></TableCell>
            <TableCell align="center"><b>Username</b></TableCell>
            <TableCell align="center"><b>Password</b></TableCell>
            <TableCell align="center"><b>uniqueness</b></TableCell>
            <TableCell align="center"><b>GroupT</b></TableCell>
            <TableCell align="center"><b>Reset Login</b></TableCell>
            <TableCell align="center"><b>Update User</b></TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row, i) => (
            <TableRow key={i}>
              <TableCell component="th" align="center" scope="row">{i + 1 + (page * rowsPerPage)}</TableCell>
              <TableCell align="center" >{row.username}</TableCell>
              <TableCell align="center">{row.password}</TableCell>
              <TableCell align="center">{row.uniqueness}</TableCell>
              <TableCell align="center">{row.groupT}</TableCell>
              <TableCell align="center"><ResetSystemxUserDialogueBox row={row} database={database} fetchAllDataByOption={fetchAllDataByOption} name="Reset Login" variant="primary" /></TableCell>
              <TableCell align="center"><SystemXUpdateUserModel row={row} database={database} fetchAllDataByOption={fetchAllDataByOption} name="Update User" variant="primary" /></TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MaterialSystemXUserDetailTable
