import {ReactElement} from 'react';

const AdminHeader = ({children}) => {
    return (
        <>
            <div className={'my-3'}>
                <h2 className={'font-weight-bold '}
                    style={{fontSize: '18px', lineHeight: '24px', fontFamily: '"Inter",sans-serif'}}>
                    {children}
                </h2>
            </div>
        </>
    );
};

export default AdminHeader;
