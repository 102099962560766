import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

//Higher Order Routes
import PublicRoute from "./hoc/PublicRoute";
import ProtectedRoute from "./hoc/ProtectedRoute";

//Components
import NotFound from "./containers/notfound/NotFound";
import Login from "./containers/login/Login";
import UpdateExpiryPage from "./containers/updateExpiry/UpdateExpiryPage";
import ViewUpdateLogs from "./containers/logs/ViewUpdateLogs";
import Signup from "./containers/signup/Signup";
import AdminOnlyRoute from "./hoc/AdminOnlyRoute";
import ViewSystemUsers from "./containers/systemUsers/ViewSystemUsers";
import AddUpdate from "containers/template/AddUpdate";
import CompanyInfo from "./Pages/CompanyInfo";
import FinancialUpdates from "./containers/financials/FinancialUpdates";
import HistoricalNAV from "./containers/historicalNav/HistoricalNAV";

//all services router
import paisaipaisaRouter from "./routes/paisaipaisa";
import featureFlagRouter from "./routes/featureFlag";
import MutualFunds from "containers/financials/MutualFunds";
import Ipo from "containers/financials/Ipo";
// import Home from './containers/home/Home'

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          {/* <ProtectedRoute exact path='/home' component={Home} /> */}
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <PublicRoute exact path="/login" component={Login} />
          <ProtectedRoute
            exact
            path="/manualupdate"
            component={UpdateExpiryPage}
          />

          <ProtectedRoute exact path="/addupdate" component={AddUpdate} />
          <ProtectedRoute
            exact
            path="/addupdate/financialUpdates"
            component={FinancialUpdates}
          />
          <ProtectedRoute
            exact
            path="/addupdate/mutualFunds"
            component={MutualFunds}
          />
          <ProtectedRoute exact path="/addupdate/ipo" component={Ipo} />
          {/* <ProtectedRoute exact path='/signup' component={Signup}/> */}
          <AdminOnlyRoute exact path="/signup" component={Signup} />
          <AdminOnlyRoute
            exact
            path="/system/users"
            component={ViewSystemUsers}
          />
          <ProtectedRoute exact path="/logs" component={ViewUpdateLogs} />
          <ProtectedRoute exact path="/notfound" component={NotFound} />
          <ProtectedRoute exact path="/companyInfo" component={CompanyInfo} />
          <ProtectedRoute
            exact
            path="/historicalNav"
            component={HistoricalNAV}
          />

          {paisaipaisaRouter.map(({ title, component, url }) => (
            <ProtectedRoute
              exact
              path={url}
              component={component}
              title={title}
            />
          ))}

          {featureFlagRouter.map(({ title, component, url }) => (
            <ProtectedRoute
              exact
              path={url}
              component={component}
              title={title}
            />
          ))}
          <Route path={"*"}>
            <NotFound />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
