import moment from "moment";
import { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  TimePicker,
  message,
} from "antd";
import { useHistory } from "react-router-dom";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BSDatePicker from "containers/template/BSDatePicker";
import MaterialAppBar from "components/MaterialAppBar";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import "../template/addUpdate.css";
import { getHeader } from "methods/actions";
import axiosOrder from "axios-order";

const useForm = () => {
  const [form] = Form.useForm();

  return form;
};
const { TextArea } = Input;
const { Option } = Select;

const Ipo = () => {
  const [eventDate, setEventDate] = useState({});
  const [openDate, setOpenDate] = useState({});
  const [closeDate, setCloseDate] = useState({});
  const [bookCloseDate, setBookCloseDate] = useState({});
  const [allotmentDate, setAllotmentDate] = useState({});
  const [apiInputTypeData, setApiInputTypeData] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [detailsList, setDetailsList] = useState([]);
  const [latestFiscalYear, setLatestFiscalYear] = useState(null);
  const [fiscalYearList, setFiscalYearList] = useState([]);

  const current_date = moment();

  const form = useForm();
  let history = useHistory();
  const header = getHeader();

  const detailsTypeDetermination = (val) => {
    form.setFieldValue("DetailsType", "");
    const details = apiInputTypeData.filter((i) => i.Type === val);
    const detailOptions = details.map((d) => d.DetailsTypeOption);
    setDetailsList(detailOptions);
  };

  const displayAddUpdates = (event) => {
    event.preventDefault();
    history.push("/addUpdate");
  };

  const displayFinancialUpdates = (event) => {
    event.preventDefault();
    history.push("/addUpdate/financialUpdates");
  };
  const displayMutualFunds = (event) => {
    event.preventDefault();
    history.push("/addUpdate/mutualFunds");
  };
  const displayIpo = (event) => {
    event.preventDefault();
    history.push("/addUpdate/ipo");
  };

  const detailsDetermination = (val) => {
    const input_type = form.getFieldValue("InputType");
    const selectedDetailAuto = apiInputTypeData.find(
      (a) => a.DetailsTypeOption === val && a.Type === input_type
    );

    form.setFieldValue("Details", selectedDetailAuto?.Details);
    form.setFieldValue("Agenda", selectedDetailAuto?.Agenda);
  };

  useEffect(() => {
    getCurrentFiscalYear();
    getLastFiveFiscalYear();
    getUpdateTemplate();
  }, []);

  useEffect(() => {
    if (latestFiscalYear) {
      form.setFieldValue("FiscalYear", latestFiscalYear.englishFiscalYear);
    }
  }, [latestFiscalYear]);

  const getCurrentFiscalYear = () => {
    axiosOrder
      .get(`/fiscal-year/current`, header)
      .then((response) => {
        setLatestFiscalYear(response.data.message);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const getLastFiveFiscalYear = () => {
    axiosOrder
      .get(`/fiscal-year/last-five`, header)
      .then((response) => {
        setFiscalYearList(response.data.message);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const getUpdateTemplate = () => {
    axiosOrder
      .get(`/updates/update-template`, header)
      .then((response) => {
        setApiInputTypeData(response.data.message);

        let typeOptions = [];
        response.data.message.forEach((r) => {
          if (!typeOptions.includes(r.Type)) {
            typeOptions.push(r.Type);
          }
        });
        setTypeOptions(typeOptions);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  useEffect(() => {
    // NOTE : set default value IPO | on initial api input type set
    if (!apiInputTypeData?.length) return;

    form.setFieldValue("InputType", "IPO");
    detailsTypeDetermination("IPO");
  }, [apiInputTypeData]);

  const onFinish = (values) => {
    console.log("Success :", values);

    let inputDate = moment().format("YYYY-MM-DD");
    if (values.InputDate) {
      inputDate = values.InputDate.format("YYYY-MM-DD");
    }

    const formattedTime = moment(values.Time, "h:mm A").format("hh:mm A");

    const form_values = {
      ...values,
      TKR: values.TKR,
      Company: values.Company,
      Time: formattedTime === "Invalid date" ? null : formattedTime,
      InputDate: inputDate ? inputDate : null,
      EventDate: eventDate.english ? eventDate.english : null,
      OpenDate: openDate.english ? openDate.english : null,
      CloseDate: closeDate.english ? closeDate.english : null,
      BookCloseDate: bookCloseDate.english ? bookCloseDate.english : null,
      AllotmentDate: allotmentDate.english ? allotmentDate.english : null,
      DetailsType: values.DetailsType ? values.DetailsType : null,
      Details: values.DetailsType ? values.Details : null,
      Agenda: values.DetailsType ? values.Agenda : null,
      InputType: values.InputType ? values.InputType : null,
      LastDayNameTransfer: values.LastDayNameTransfer
        ? values.LastDayNameTransfer
        : null,
      ShareRegistrar: values.ShareRegistrar ? values.ShareRegistrar : null,
      Venue: values.Venue ? values.Venue : null,
    };

    axiosOrder
      .post("/updates/add-update", form_values, header)
      .then(function (response) {
        if (response.data.code === 200) {
          console.log("Success in adding update", response);
          message.success(response.data.message);
          form.resetFields();
          form.setFieldsValue({
            Details: null,
            Agenda: null,
            inputDate: null,
          });
          setEventDate({ nepali: "", english: "" });
          resetDate("event-id");

          setOpenDate({ nepali: "", english: "" });
          resetDate("open-date");

          setCloseDate({ nepali: "", english: "" });
          resetDate("close-date");

          setBookCloseDate({ nepali: "", english: "" });
          resetDate("book-close-date");

          setAllotmentDate({ nepali: "", english: "" });
          resetDate("allotment-date");
          getCurrentFiscalYear();
        } else {
          console.log("error in adding update", response);
          message.error(response.data.message);
        }
      })
      .catch(function (error) {
        console.log("error in adding update", error);
        message.error("Failed to add update.");
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("asd Failed:", errorInfo);
  };

  const resetDate = (id) => {
    const resetButton = document.querySelector(`#${id} #resetDateButton`);
    if (resetButton) {
      resetButton.click();
    }
  };

  return (
    <div>
      <div>
        <MaterialAppBar />
        <div className="wrapperNav">
          <div className="wrapperNavIpo">
            <FontAwesomeIcon
              icon={faLeftLong}
              size="2xl"
              onClick={displayAddUpdates}
              className="leftLongIcon"
            />
            <h3 className="headingNav">Add Ipo</h3>
          </div>
          <div className="rightWrapperNav">
            <button
              className="trasparentButton"
              onClick={displayFinancialUpdates}
            >
              Add Financial Updates
              <FontAwesomeIcon
                icon={faCirclePlus}
                style={{ color: "#3F51B5" }}
                className="circlePlusIcon"
              />
            </button>
            <button onClick={displayMutualFunds} className="trasparentButton">
              Mutual Funds
              <FontAwesomeIcon
                icon={faCirclePlus}
                style={{ color: "#3F51B5" }}
                className="circlePlusIcon"
              />
            </button>
            <button onClick={displayIpo} className="trasparentButton">
              IPO
              <FontAwesomeIcon
                icon={faCirclePlus}
                style={{ color: "#3F51B5" }}
                className="circlePlusIcon"
              />
            </button>
          </div>
        </div>

        <div className="addUpdateForm">
          <Form
            name="addUpdates"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="row">
              <div className="col">
                <Form.Item
                  label="TKR"
                  name="TKR"
                  rules={[
                    {
                      required: true,
                      message: "Please input TKR!",
                    },
                  ]}
                  style={{ textAlign: "left" }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Company"
                  name="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please input company name!",
                    },
                  ]}
                  style={{ textAlign: "left" }}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="InputDate"
                  name="InputDate"
                  rules={[
                    {
                      required: true,
                      message: "Please provide input date!",
                    },
                  ]}
                  initialValue={current_date}
                >
                  <DatePicker format="YYYY-MM-DD" value={current_date} />
                </Form.Item>

                <Form.Item
                  label="InputType"
                  name="InputType"
                  rules={[
                    {
                      required: true,
                      message: "Please provide input type!",
                    },
                  ]}
                >
                  <Select
                    onChange={detailsTypeDetermination}
                    options={typeOptions.map((a) => {
                      return {
                        label: a,
                        value: a,
                      };
                    })}
                  ></Select>
                </Form.Item>

                <Form.Item
                  label="DetailsType"
                  name="DetailsType"
                  rules={[
                    {
                      required: true,
                      message: "Please input detail type!",
                    },
                  ]}
                >
                  <Select
                    options={detailsList.map((a) => {
                      return { label: a, value: a };
                    })}
                    onChange={detailsDetermination}
                  ></Select>
                </Form.Item>

                <Form.Item
                  label="Details"
                  name="Details"
                  rules={[
                    {
                      required: true,
                      message: "Please input details!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item label="FiscalYear" name="FiscalYear">
                  <Select
                    options={fiscalYearList.map((a) => {
                      return {
                        label: a.englishFiscalYear,
                        value: a.englishFiscalYear,
                      };
                    })}
                  ></Select>
                </Form.Item>

                <Form.Item label="ShareRegistrar" name="ShareRegistrar">
                  <Input></Input>
                </Form.Item>

                <Form.Item label="EventDate" className="adjustMargin">
                  <BSDatePicker
                    id="event-id"
                    name="EventDate"
                    date={eventDate}
                    setDate={setEventDate}
                  />
                </Form.Item>

                <Form.Item label="OpenDate" className="adjustMargin">
                  <BSDatePicker
                    id="open-date"
                    name="OpenDate"
                    date={openDate}
                    setDate={setOpenDate}
                  />
                </Form.Item>
              </div>
              <div className="col">
                <Form.Item label="CloseDate" className="adjustMargin">
                  <BSDatePicker
                    id="close-date"
                    name="CloseDate"
                    date={closeDate}
                    setDate={setCloseDate}
                  />
                </Form.Item>

                <Form.Item label="BookCloseDate" className="adjustMargin">
                  <BSDatePicker
                    id="book-close-date"
                    name="BookCloseDate"
                    date={bookCloseDate}
                    setDate={setBookCloseDate}
                  />
                </Form.Item>

                <Form.Item
                  label="LastDayNameTransfer"
                  name="LastDayNameTransfer"
                >
                  <DatePicker format="YYYY-MM-DD" />
                </Form.Item>

                <Form.Item label="Time" name="Time">
                  <TimePicker use12Hours format="h:mm A" />
                </Form.Item>

                <Form.Item label="Venue" name="Venue">
                  <Input></Input>
                </Form.Item>

                <Form.Item label="AllotmentDate" className="adjustMargin">
                  <BSDatePicker
                    id="allotment-date"
                    name="AllotmentDate"
                    date={allotmentDate}
                    setDate={setAllotmentDate}
                  />
                </Form.Item>

                <Form.Item
                  label="Agenda"
                  name="Agenda"
                  rules={[
                    {
                      required: true,
                      message: "Please input agenda!",
                    },
                  ]}
                >
                  <TextArea rows={6}></TextArea>
                </Form.Item>

                <div className="saveButton">
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Ipo;
