import {Button, message, Switch, Table} from "antd";
import FlowvityServices from "../../components/FeatureFlag/Common/FlowvityServices/FlowvityServices";
import sideBarItems from "../../components/SideBar/sideBarItems";
import SideBarLayout from "../../components/SideBar";
import {useEffect, useState} from "react";
import axios from "axios";
import {changeTableDataFormat, replaceObject, replaceObjectById, replaceObjectValue} from "../../methods/actions";
import GenericSearch from "../../components/GenericSearch";

const FlowvityAdminPanel = () => {
    const [tableData, setTableData] = useState([]);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [response, setResponse] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        fetchTableData();
    }, []);

    useEffect(() => {
        dynamicCompanyColumns(tableData[0]);
    }, [tableData]);

    const fetchTableData = async () => {
        try {
            const {data} = await axios.get(`${process.env.FEATURE_FLAG_SERVER_URL}/admin-panel/`);
            const updatedData = changeTableDataFormat(data.data);
            setResponse(data.data);
            setTableData(updatedData);
        } catch (e) {
            console.error("Error while fetching admin panel", e);
        }
    }

    const toggleSwitch = (record, cell) => {
        const newTableData = [...tableData];
        const newPostData = [...response];
        const updatedRecord = {...record, [cell]: !record[cell]};
        replaceObject(newTableData, "id", record.id, updatedRecord);
        // replaceObjectValue(newTableData,"id",[cell],record.id,!record[cell]);
        replaceObjectValue(newPostData, "company", record.service, cell, !record[cell]);
        console.log("record",record);
        setTableData(newTableData);
        setResponse(newPostData);
    }

    const onFinish = async () => {
        setSubmitLoading(true);
        try {
            const {data} = await axios.post(`${process.env.FEATURE_FLAG_SERVER_URL}/admin-panel/update-features`, {tableDatas: response});
            data.success ? message.success(data.message) : message.error(data.message);
        } catch (e) {
            console.error("error while submitting updated admin panel features.");
        } finally {
            setSubmitLoading(false);
        }
    }

    const dynamicCompanyColumns = (dataSource) => {
        if (dataSource) {
            const {id, service, ...companies} = dataSource;
            const columns = Object.keys(companies).map((item) => {
                return {
                    title: item.toUpperCase(),
                    key: item,
                    dataIndex: item,
                    render: (text, record, index) => (
                        <Switch defaultChecked={record[item]}
                                onChange={() => toggleSwitch(record, item)}/>
                    )
                };
            });
            setDynamicColumns(columns);
        }
    }

    const columns = [
        {
            title: "SERVICES",
            key: "service",
            dataIndex: "service",
            sorter: (a, b) => a.service < b.service,
        },
        ...dynamicColumns
    ]

    return (
        <SideBarLayout siderItems={sideBarItems.featureFlag}>
            <div>
                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    <FlowvityServices defaultValue={"adminPanel"}/>
                    {/*<GenericSearch mainData={tableData} setFilteredData={setFilteredData}*/}
                    {/*               setSearchLoading={setSearchLoading}/>*/}
                </div>
                <Table columns={columns} dataSource={searchLoading ? filteredData : tableData} pagination={false} size={"middle"}/>
                <div style={{display:"flex", justifyContent:"flex-end", alignItems:"center", paddingBlock:"20px"}}>
                    <Button
                        type="primary"
                        loading={submitLoading}
                        onClick={onFinish}
                        style={{paddingInline:"60px"}}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </SideBarLayout>
    )
};

export default FlowvityAdminPanel;