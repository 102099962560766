import React, {useEffect, useState} from 'react';
import CustomTable from '../../../components/PaisaiPaisa/common/GenericTable';
import AdminHeader from '../../../components/PaisaiPaisa/common/adminHeader';
import {Button, DatePicker, Form, message, Select, Tabs} from 'antd';
import axios from "axios";
import {getHeader} from "../../../methods/actions";
import FixedDepositTable from "../../../components/PaisaiPaisa/common/GenericTable/fixedDepositTable";
import SavingAccountTable from "../../../components/PaisaiPaisa/common/GenericTable/savingAccountTable";
import sideBarItems from "../../../components/SideBar/sideBarItems";
import SideBarLayout from "../../../components/SideBar";

const {TabPane} = Tabs;
const {MonthPicker} = DatePicker;

const SavingRate = () => {
    const [company, setCompany] = useState(null);
    const [others, setOthers] = useState(null);
    const [response, setResponse] = useState(null);
    const [dropDownData, setDropDownData] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [tableData, setTableData] = useState([]);
    // const [paramDate, setParamDate] = useState(null);
    const header = getHeader();


    useEffect(() => {
        getBankList().then(r => console.log('r', r));
    }, []);

    const getBankList = async () => {
        try {
            const bankList = await axios.get(`${process.env.REACT_APP_SERVER_URL}/paisai-paisa/bank-lists`, header);
            setDropDownData(bankList.data.message)
            setInitialValues({company: bankList.data.message[0].companyName})
        } catch (e) {
            console.log(e)
        }
    }

    const postData = async (result) => {

        try {
            const submitData = {
                formData: result,
            }
            const postReponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/paisai-paisa/saving-rates/update`, submitData, header)
            if (postReponse.data.success) {
                message.success(postReponse.data.message);
            } else {
                message.error(postReponse.data.message);
            }
        } catch (e) {
            console.log(e);
            message.error(e.message);
        }
    }


    const getTableData = async (company) => {

        try {
            const getResponse = await axios.get(process.env.REACT_APP_SERVER_URL + `/paisai-paisa/saving-rates?company=${company}`, header);
            setCompany(company);
            setResponse(getResponse);
            setTableData(getResponse.data.message);
        } catch (e) {
            console.log('e', e);
        }
    }

    const onFinish = async (values) => {
        await getTableData(values.company);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed', errorInfo);
    }
    return (
        <SideBarLayout siderItems={sideBarItems.paisaiPaisa}>

            <AdminHeader>
                SAVINGS ACCOUNT RATES
            </AdminHeader>
            {Object.keys(initialValues).length !== 0 &&
                <Form
                    layout='inline'
                    className={'mb-2 d-flex'}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={initialValues}
                >
                    <div className={'d-flex mr-3'}>
                        <Form.Item label={'CHOOSE COMPANY'} className={'d-flex mr-5'}
                                   name={`company`}
                                   rules={[{required: true, message: 'Please select company'}]}
                        >
                            <Select
                                showSearch
                                style={{minWidth: 200}}
                                optionFilterProp='children'
                                // onChange={industryChange}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {dropDownData.map((item, index) =>
                                    (
                                        <Select.Option key={index}
                                                       value={item.companyName}>{item.companyName}</Select.Option>
                                    ),
                                )}
                            </Select>
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Button htmlType={'submit'}
                                style={{background: '#FFCE00', paddingInline: '20px', fontWeight: 600}}>VIEW</Button>
                    </Form.Item>
                </Form>
            }
            {response &&
                <SavingAccountTable tableData={tableData} postData={postData}/>
            }
        </SideBarLayout>
    );
};

export default SavingRate;
