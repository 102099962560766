import React, {Component} from 'react';
import {message, Spin} from 'antd';
import {LoadingOutlined} from "@ant-design/icons";

import {getHeader} from "../methods/actions";


const antIcon = <LoadingOutlined style={{fontSize: 40}} spin/>;

const withLoaderHandler = (WrappedComponent, axios, loader = true) => {
    // return (props) => {
    //
    //     let history = useHistory();
    //
    //     const [isLoading, setIsLoading] = useState(null);
    //
    //     const [reqInterceptor, setReqInterceptor] = useState(null);
    //     const [resInterceptor, setResInterceptor] = useState(null);
    //
    //     const grabErrors = (err) => {
    //         if (!err.response && err.message !== 'THROTTLE') {
    //             message.error(
    //                 "Something went wrong")
    //         } else if (err.response && err.response.status === 401) { // Logout Use
    //             message.error("Token expired", 5);
    //             localStorage.removeItem("token");
    //             localStorage.removeItem("key");
    //         } else {
    //             if (err.message !== 'THROTTLE') {
    //                 message.error('Failed to perform last action', 5)
    //             }
    //         }
    //     }
    //     useEffect(() => {
    //         axios.interceptors.request.use(function (config) {
    //             console.log(config);
    //             setIsLoading(loader);
    //             let data = getHeader();
    //             config.headers.authorization = data.headers.Authorization;
    //             return config
    //         }, function (error) {
    //             grabErrors(error);
    //             setIsLoading(false);
    //             return Promise.reject(error);
    //         });
    //
    //         console.log("after req interceptor");
    //
    //         const resInterceptor = axios.interceptors.response.use(function (response) {
    //             // spinning hide
    //             setIsLoading(false);
    //             return response;
    //         }, function (error) {
    //             // Mark as offline if Axios does not have any status code in response. (Possible Network Error i.e. window.navigator might be online but no internet access)
    //             if (error.response && error.response.data && error.response.data === 'token expired') {
    //                 message.error(error.response.data.toUpperCase(), 5);
    //                 localStorage.removeItem('jwtToken');
    //                 localStorage.removeItem('user');
    //                 history.push("/login");
    //             }
    //
    //             if (error.response && error.response.data.message) {
    //                 message.error(error.response.data.message, 3);
    //             }
    //             setIsLoading(false);
    //
    //             return Promise.reject(error);
    //         });
    //         setReqInterceptor(reqInterceptor);
    //         setResInterceptor(resInterceptor);
    //         return () => {
    //             axios.interceptors.request.eject(reqInterceptor);
    //             axios.interceptors.response.eject(resInterceptor);
    //         }
    //     }, []);
    //
    //
    //     return (
    //         <Spin indicator={antIcon} tip="Loading" delay="10" size="large" spinning={isLoading}>
    //             <WrappedComponent loading={isLoading} {...props}/>
    //         </Spin>
    //     );
    // }
    return class WithLoaderHandler extends Component {
        state = {
            error: null,
            isLoading: false,
            reqInterceptor: null,
            resInterceptor: null
        }

        grabErrors(err) {
            console.log(err)
            if (!err.response && err.message !== 'THROTTLE') {
                message.error(
                    "Something went wrong")
            } else if (err.response && err.response.status === 401) { // Logout Use
                message.error("Token expired", 5);
                localStorage.removeItem("token");
                localStorage.removeItem("key");
            } else {
                if (err.message !== 'THROTTLE') {
                    message.error('Failed to perform last action', 5)
                }
            }
        }

        componentWillMount() {
            const _this = this
            const reqInterceptor = axios.interceptors.request.use(function (config) {
                _this.setState({isLoading: loader, error: null})
                let data = getHeader();
                config.headers.authorization = data.headers.Authorization;
                return config
            }, function (error) {
                console.log("The error is ", error);
                _this.grabErrors(error);
                _this.setState({isLoading: false, error: error})
                return Promise.reject(error);
            });

            _this.setState({reqInterceptor})

            const resInterceptor = axios.interceptors.response.use(function (response) {
                // spinning hide
                _this.setState({isLoading: false})
                return response;
            }, function (error) {
                // Mark as offline if Axios does not have any status code in response. (Possible Network Error i.e. window.navigator might be online but no internet access)
                if (error.response && error.response.data && error.response.data === 'token expired') {
                    message.error(error.response.data, 5);
                    localStorage.removeItem('jwtToken');
                    localStorage.removeItem('user');
                }

                if (error.response && error.response.data.message) {
                    message.error(error.response.data.message, 3);
                }

                _this.setState({isLoading: false, error: error})
                return Promise.reject(error);
            });

            _this.setState({resInterceptor})
        }


        componentWillUnmount() {
            axios.interceptors.request.eject(this.state.reqInterceptor);
            axios.interceptors.response.eject(this.state.resInterceptor);
        }

        render() {
            return (
                <Spin indicator={antIcon} tip="Loading" delay="10" size="large" spinning={this.state.isLoading}>
                    <WrappedComponent loading={this.state.isLoading} {...this.props}/>
                </Spin>
            );
        }
    }
}
export default withLoaderHandler;
