import React from "react";
import ReactTable from "react-table-6";
import {map, omit} from "lodash";
import {Button, message, Popconfirm, Tooltip, Empty} from "antd";

import {TiDeleteOutline} from "react-icons/ti";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import ReactPagination from "./ReactPagination";
import PropTypes from "prop-types";
import SearchBox from "../SearchBox/SearchBox";
import {List, Name} from "../Elements";
import "./reactTableCustom.css";

function capitalizeFirstLetterOnly([firstLetter, ...rest]) {
    return [firstLetter.toLocaleUpperCase(), ...rest].join("");
}

function truncateLongString(val) {
    if (val.length >= 100) {
        return val.substring(0, 50) + " ...";
    }
    return val;
}


const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';


class CustomReactTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            header: [],
            containsData: false,
            pageSizeOption: 10,
            filteredData: [],
            isSearchStarted: false,
            isLoading: false,
        };

    }

    // doing this, because, if index 0 property is undefined, then all data of that property will be skipped.
    findObjectWithGreatestProperty(data) {
        let obj;
        let smallObjLength = Object.keys(data[0]).length;
        obj = data[0];
        if (data.length <= 1) return obj;
        for (let i = 1; i < data.length; i++) {
            if (smallObjLength < Object.keys(data[i]).length) {
                obj = data[i];
                smallObjLength = Object.keys(obj).length;
            }
        }
        return obj;
    }

    getTableHeaderFromData(data) {
        const {tableOption: {showHighestColProperty}} = this.props;
        let headerText = Object.keys(data[0]);

        if (showHighestColProperty) {
            headerText = Object.keys(this.findObjectWithGreatestProperty(data));
        }
        const header = [];
        const {tableOption} = this.props;
        const {
            hideColumn,
            removeHeader,
            columnStyle,
            styleCellConditionally,
            sortableColumn,
            customCell,
            sortCol,
            addSpanToRow,
            defaultRowFormat,
            customHeader,
        } = tableOption;

        // guess....
        headerText.forEach((item) => {
            const itemObj = {};

            let show = true;
            if (hideColumn) {
                hideColumn.forEach((hideColumnItem) => {
                    if (hideColumnItem === item) {
                        show = false;
                    }
                });
                // console.log(itemObj)

                itemObj["show"] = show;
            }
            // const sortable = sortableColumn.findIndex((colName) => colName === item);
            // itemObj["sortable"] = sortable !== -1;
            itemObj["sortable"] = true;

            itemObj["id"] = item;
            if (sortCol !== undefined) {
                if (sortCol[item] !== undefined) {
                    itemObj['sortMethod'] = sortCol[item];
                }
            }
            itemObj["style"] = {whiteSpace: "unset"};
            itemObj["Header"] = () => {
                let name = item;
                if (customHeader && customHeader(headerText)[item]) {
                    name = customHeader(headerText)[item];
                    // Object.keys(customHeader).forEach((x) => {
                    //     if (x === name) {
                    //         name = customHeader[x]
                    //     }
                    // })
                }
                name = capitalizeFirstLetterOnly(
                    name.replace(/([a-z])([A-Z])/g, "$1 $2")
                );

                if (item === "uid") {
                    name = "User ID";
                }

                return (
                    <Tooltip title={name}>
                        <div
                            style={{
                                fontSize: "12px",
                                textTransform: "uppercase",
                                fontWeight: "bold",
                            }}
                            className={'wordwrap'}
                        >
                            {removeHeader !== item ? name : ""}
                        </div>
                    </Tooltip>
                );
            };

            itemObj["accessor"] = item;
            // itemObj['sortMethod'] =
            if (defaultRowFormat) {
                if (headerText.length >= 5)
                    itemObj["maxWidth"] = this.getColumnWidth(
                        itemObj["accessor"],
                        itemObj["Header"],
                        data
                    );
            }

            itemObj["Cell"] = (row) => {
                if (columnStyle) {
                    const adjustStyleOfCellObject = Object.assign({}, columnStyle);
                    let style = {};
                    let rowHeader = item;
                    Object.keys(adjustStyleOfCellObject).forEach((key) => {
                        if (rowHeader === key) {
                            style = adjustStyleOfCellObject[key];
                        }
                    });
                    return <div style={style.style}>{row.value}</div>;
                } else {
                    if (customCell && customCell(headerText) && customCell(headerText)[item]) {
                        return customCell(headerText)[item].display(row);
                    }
                    return (
                        <div style={{textAlign: "left", paddingLeft: "5px"}}>
                            {typeof row.original[item] === "string"
                                ? truncateLongString(row.original[item])
                                : JSON.stringify(row.original[item])}
                        </div>
                    );
                }
            };

            if (styleCellConditionally) {
                Object.keys(styleCellConditionally).forEach((key) => {
                    if (itemObj["accessor"] === key) {
                        itemObj["getProps"] = (state, rowInfo, column) => {
                            if (rowInfo && rowInfo.row) {
                                return {
                                    style: styleCellConditionally[key].condition(rowInfo.row)
                                        .style,
                                };
                            } else {
                                return {};
                            }
                        };
                    }
                });
            }
            header.push(itemObj);
        });
        return header;
    }

    addSpanToRow(data, row) {
        return (
            <div>
                {data.map((item, index) => {
                    return (
                        <List key={index}>
                            <Name>{item}</Name>
                            <span>
                <Popconfirm
                    title="Are You Sure, Delete This Task?"
                    onConfirm={(event) =>
                        this.props.tableOption.addSpanToRow.deleteTask(item, row)
                    }
                    placement="right"
                    okText="Yes"
                    cancelText="No"
                >
                  <Button
                      type="text"
                      danger
                      icon={
                          <TiDeleteOutline
                              style={{color: "red", fontSize: "16px"}}
                          />
                      }
                  />
                </Popconfirm>
              </span>
                        </List>
                    );
                })}
            </div>
        );
    }

    getColumnWidth(accessor, headerText, data) {
        let max = 0;
        const maxWidth = 160;
        const magicSpacing = 16;

        for (let i = 0; i < data.length; i++) {
            if (data[i] !== undefined && data[i][accessor] !== null) {
                if (JSON.stringify(data[i][accessor] || "null").length > max) {
                    max = JSON.stringify(data[i][accessor] || "null").length;
                }
            }
        }
        return Math.min(maxWidth, Math.max(max, accessor.length) * magicSpacing);
    }

    mapArray(array) {
        const {tableOption} = this.props;
        const {tableRowRemove} = tableOption;

        return map(
            array,
            (object) => omit(object, tableRowRemove) // return from _.omit
        );
    }

    convertBooleanIntoString(array) {
        return array.map((item, index) => {
            for (let itemObj in item) {
                if (item[itemObj] === true) {
                    item[itemObj] = "true";
                } else if (item[itemObj] === false) {
                    item[itemObj] = "false";
                } else if (typeof item[itemObj] === 'number') {
                    item[itemObj] = item[itemObj].toString().split('.')[0].replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
                } else if (item[itemObj] === 'no value') {
                    item[itemObj] = '-';
                } else if (item[itemObj] === null || item[itemObj] === undefined) {
                    item[itemObj] = '-';
                }
                // else if (Array.isArray(item[itemObj])) {
                //     item[itemObj] = item[itemObj].join(', ');
                // }
            }
            return item;
        });
    }

    componentDidMount() {
        const {tableOption} = this.props;
        let {tableData, tableRowRemove, buttons} = tableOption;
        if (tableData) {
            try {
                tableData = [...tableData];
                if (tableData.length !== 0) {
                    tableData = this.convertBooleanIntoString(tableData);
                    if (tableRowRemove) {
                        tableData = this.mapArray(tableData);
                    }
                    let header = this.getTableHeaderFromData(tableData);
                    if (buttons) {
                        this.addButtonToRow().forEach((item) => {
                            header.push(item);
                        });
                    }
                    this.setState({
                        header: header,
                        containsData: true,
                        data: tableData,
                    });
                } else this.setState({containsData: false});
            } catch (error) {
                this.setState({containsData: false, data: []});
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {tableOption} = this.props;
        let {tableData, tableRowRemove, buttons} = tableOption;
        if (prevProps !== this.props
        ) {
            if (tableData.length !== 0) {
                tableData = this.convertBooleanIntoString(tableData);
                if (tableRowRemove) {
                    tableData = this.mapArray(tableData);
                }
                let header = this.getTableHeaderFromData(tableData);
                if (buttons) {
                    this.addButtonToRow().forEach((item) => {
                        header.push(item);
                    });
                }

                this.setState({header: header, containsData: true, data: tableData});
            } else {
                this.setState({containsData: false});
            }
        }
    }

    onChangeTableState(value, data) {
        this.setState({isLoading: true});
        let mainData = [...data];
        let searchValue = value.trim().toLowerCase();
        if (searchValue.length === 0) {
            this.setState({isSearchStarted: false, pageSizeOption: 8});
        } else this.setState({isSearchStarted: true, pageSizeOption: 8});

        mainData = JSON.stringify(mainData, (k, v) =>
            v && typeof v === "object" ? v : "" + v
        );
        mainData = JSON.parse(mainData);
        // if (this.state.isSearchStarted) {
        let filteredData = mainData.filter((o) =>
            Object.keys(o).some((key) =>
                JSON.stringify(o[key]).toLowerCase().includes(searchValue)
            )
        );
        if (filteredData.length !== 0) {
            this.setState({filteredData: filteredData});
        } else {
            this.setState({filteredData: [], pageSizeOption: 8});
        }
        this.setState({isLoading: false});
    }

    exportButton() {
        let filteredPureArray;
        if (!this.state.isSearchStarted) {
            filteredPureArray = [...this.state.data];
        } else {
            filteredPureArray = [...this.state.filteredData];
        }
        if (filteredPureArray.length <= 0) {
            message.error("No Data To Export");
            return;
        }
        this.downloadExcelSheet(filteredPureArray);
    }

    downloadExcelSheet(csvData) {
        const {tableOption: {downloadFileName}} = this.props;
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], {type: fileType});
        let fileName = "Untitled";
        if (downloadFileName) {
            fileName = downloadFileName;
        }
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    getTrProps = (state, rowInfo, instance) => {
        const {onRowClicked} = this.props.tableOption;
        if (rowInfo) {
            return {
                onClick: () => {
                    let tableRow = [...this.state.data];
                    let tableFilteredRow = [...this.state.filteredData];
                    let selectedObject;

                    if (this.state.isSearchStarted) {
                        selectedObject = tableFilteredRow[rowInfo.row._index];
                    } else {
                        selectedObject = tableRow[rowInfo.row._index];
                    }
                    try {
                        if (onRowClicked) {
                            onRowClicked(selectedObject);
                        }
                    } catch (ex) {
                        console.log(ex);
                    }
                },
                style: {
                    display: "flex",
                    alignItems: "center",
                    // fontWeight: "",
                    fontSize: "13px",
                    color: "black",
                },
            };
        }
        return {};
    };

    getTableRow(row) {
        let tableRow = [...this.state.data];
        let tableFilteredRow = [...this.state.filteredData];
        let selectedObject = {};

        if (this.state.isSearchStarted) {
            selectedObject = tableFilteredRow[row._index];
        } else {
            selectedObject = tableRow[row._index];
        }
        return selectedObject;
    }

    addButtonToRow() {
        const addedButton = this.props.tableOption.buttons;
        if (!Array.isArray(addedButton)) {
            throw new Error("It is not Array");
        }
        let buttonArray;
        // console.log(addedButton);
        buttonArray = addedButton
            .filter((item) => !item.disabled)
            .map((item) => {
                return {
                    Header: item.Header,
                    accessor: item.accessor,
                    sortable: false,
                    Cell: (row) => (
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <div aria-disabled={true} onClick={() => item.onClick(row.row)}>
                                {item.buttonName}
                                {item.icons}
                            </div>
                        </div>
                    ),
                    maxWidth: 120,
                };
            });
        return buttonArray;
    }


    saveTableState = (value, data) => {
        console.log(data);
        if (!value) {
            this.setState({filteredData: [], isSearchStarted: false, pageSizeOption: 8});
            return;
        }
        if (data && Array.isArray(data)) {
            this.setState({filteredData: data, isSearchStarted: true, pageSizeOption: 8});
        } else {
            this.setState({filteredData: [], isSearchStarted: false, pageSizeOption: 8});
        }
        this.setState({isLoading: false});
    }


    render() {
        const {data, filteredData} = this.state;
        let {
            tableOption,
            isUploadVisible,
            isSearchable,
            onUploadClick,
            isDownloadable,
            title
        } = this.props;
        const {
            tableHeight, showTableLength, tableData, onSearchTable,
            showSearch
        } = tableOption;
        let searchBox = null;
        if (isSearchable) {
            searchBox = (
                <SearchBox
                    isUploadVisible={isUploadVisible}
                    length={tableData.length}
                    showTableLength={showTableLength}
                    onDownloadClick={this.exportButton.bind(this)}
                    isVisible={isDownloadable}
                    title={title}
                    onUploadClick={() => onUploadClick(data, Object.keys(this.findObjectWithGreatestProperty(data)))}
                    onSearchClick={async (value) => {
                        if (onSearchTable && typeof onSearchTable === "function") {
                            const finalData = await onSearchTable(value, data);
                            this.saveTableState(value, finalData);
                        } else {
                            this.onChangeTableState(value, data);
                        }
                    }
                    }
                />
            );
        }
        let isScrollable = true;
        if (tableHeight) {
            isScrollable = false;
        }
        let component;
        if (this.state.containsData || showSearch) {
            component = (
                <div
                    style={{
                        marginTop: "0.5em",
                        marginBottom: "1em",
                    }}
                >
                    {searchBox}
                    <ReactTable
                        style={{
                            backgroundColor: "white",
                            height: tableHeight,
                        }}
                        sortable
                        multiSort
                        className="-striped -highlight"
                        pageSize={
                            !isScrollable
                                ? this.state.data.length
                                : this.state.data.length <= 5
                                    ? this.state.data.length
                                    : this.state.pageSizeOption
                        }
                        resizable={true}
                        data={
                            this.state.isSearchStarted
                                ? filteredData
                                : data
                        }
                        getTheadThProps={(state, rowInfo, column) => {
                            return {
                                style: {
                                    margin: "2px 0",
                                    color: "#415094",
                                    fontWeight: "bold",
                                },
                            };
                        }}
                        filtered={filteredData}
                        getTrProps={this.getTrProps}
                        columns={this.state.header}
                        // previousText="&#x3c;"
                        // nextText="&#x3e;"
                        loading={this.state.isLoading}
                        // noDataText={"NO Data"}
                        showPagination={isScrollable}
                        PaginationComponent={ReactPagination}
                        NoDataComponent={CustomNoDataComponent}
                    />
                </div>
            );
        } else {
            component = (
                <div>
                    <h5>{title}</h5>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: "200px",
                            marginTop: "20px",
                            marginBottom: "1em",
                            borderRadius: "5px",
                            backgroundColor: "white",
                            border: "1px solid #cbc9c9",
                        }}
                    >
                        <Empty image={Empty.PRESENTED_IMAGE_DEFAULT}/>
                    </div>
                </div>
            );
        }
        return <div>{component}</div>;
    }
}

const CustomNoDataComponent = ({state, ...rest}) => {
    return (
        <div className="rt-noData">
            <Empty/>
        </div>
    );
};

CustomReactTable.propTypes = {
    tableOption: PropTypes.shape({
        tableData: PropTypes.array.isRequired,
        HeaderToLeft: PropTypes.bool,
        HeaderToRight: PropTypes.bool,
        showHighestColProperty: PropTypes.bool,
        HeaderToCenter: PropTypes.bool,
        tableRowRemove: PropTypes.array,
        hideColumn: PropTypes.array,
        showColumn: PropTypes.array,
        defaultRowFormat: PropTypes.bool,
        sortCol: PropTypes.object,
        onRowClicked: PropTypes.func,
        buttons: PropTypes.array,
        sortableColumn: PropTypes.array,
        downloadFileName: PropTypes.string,
        styleCellConditionally: PropTypes.object,
        lang: PropTypes.string,
        showSearch: PropTypes.bool,
        onSearchTable: PropTypes.func,
        showTableLength: PropTypes.bool,
        customHeader: PropTypes.func,
        customCell: PropTypes.any,
    }),
    onUploadClick: PropTypes.func,
    isSearchable: PropTypes.bool.isRequired,
    title: PropTypes.string,
    isDownloadable: PropTypes.bool.isRequired,
    isUploadVisible: PropTypes.bool.isRequired,
};

export default CustomReactTable;
