import jwt_decode from "jwt-decode";
import moment from "moment";
import { Redirect } from "react-router-dom";
import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";

export const decryptStoredToken = () => {
  if (localStorage.getItem("jwtToken")) {
    const encryptedToken = localStorage.getItem("jwtToken");
    var decryptedToken = CryptoAES.decrypt(
      encryptedToken.toString(),
      "fafhao#4fa"
    );
    // console.log("decryptedToken", decryptedToken);
    // console.log("decryptedToken", decryptedToken.toString(CryptoENC));
    // return decryptedToken;
    return decryptedToken.toString(CryptoENC);
  }
  return null;
};

export const getHeader = () => {
  const token = decryptStoredToken();
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const encryptAndStoreTokenAndUserName = (token) => {
  var logintime = moment().format("lll");
  var cypherTime = CryptoAES.encrypt(logintime, "fafhao#4fa");
  localStorage.setItem("loginTime", cypherTime);
  var ciphertext = CryptoAES.encrypt(token, "fafhao#4fa");
  localStorage.setItem("jwtToken", ciphertext);
  var decoded = jwt_decode(token);
  // console.log('time is',decoded.newUser.loginTime);
  localStorage.setItem("user", decoded.newUser.username);
};

export const isAuthenticated = () => {
  // console.log("is auth called");
  try {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      var encoded = decryptStoredToken();
      var decoded = jwt_decode(encoded);
      return (
        decoded?.newUser.email &&
        decoded?.newUser.username === localStorage.getItem("user")
      );
    } else {
      return false;
    }
  } catch (e) {
    // console.log(e);
    return false;
  }
};
const isDevelopmentMode = () => {
  return process.env.NODE_ENV === "development";
};

export const isLoginTimeExpired = () => {
  try {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      var encoded = decryptStoredToken();
      var decoded = jwt_decode(encoded);
      let presentTime = moment(new Date());
      let loginTime = moment(decoded.newUser.loginTime);
      // console.log(presentTime.diff(loginTime, 'minutes'));
      var elapsedTime = presentTime.diff(loginTime, "minutes");
      // console.log("elapsed time is", elapsedTime);
      if (elapsedTime < isDevelopmentMode() ? 119 : 19) {
        return false;
      } else {
        logUserOut();
        return true;
      }
    } else {
      logUserOut();
      return true;
    }
  } catch (e) {
    console.log(e);
    logUserOut();
    return true;
  }
};

export const logUserOut = (event) => (dispatch) => {
  event.preventDefault();
  // let history = useHistory();
  // console.log("logout called");
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("user");
  // console.log(localStorage.getItem('jwtToken'));
  // history.push('/login');
  <Redirect to="/login" />;
};

export const changeTableDataFormat = (data) => {
  const newData = [];
  const { company, id, ...services } = data[0];
  const serviceKeys = Object.keys(services);
  serviceKeys.forEach((serviceKey, index) => {
    const serviceObj = { service: serviceKey, id: index };
    data.forEach((companyData) => {
      serviceObj[companyData.company] = companyData[serviceKey];
    });
    newData.push(serviceObj);
  });
  return newData;
};

export const replaceObject = (array, identifier, id, newObject) => {
  const indexToUpdate = array.findIndex((obj) => obj[identifier] === id);

  if (indexToUpdate !== -1) {
    array[indexToUpdate] = newObject;
    console.log(`Replaced object with ${identifier} ${id}`);
  } else {
    console.log(`No object found with ${identifier} ${id}`);
  }
};

export const replaceObjectValue = (
  array,
  identifier,
  property,
  id,
  newValue
) => {
  const indexToUpdate = array.findIndex((obj) => obj[identifier] === id);

  // Check if an object with the given ID was found
  if (indexToUpdate !== -1) {
    // Update the value property of the object
    array[indexToUpdate][property] = newValue;
    console.log(`Updated value for object with ID ${id}`);
  } else {
    console.log(`No object found with ID ${id}`);
  }
};
