import React, {useEffect, useState} from 'react';
import {Badge, Button, Divider, Form, Typography, Space, message} from "antd";

import CommonAddModalBox from "./Common/CommonAddModalBox";
import axiosOrder from "../../../axios-order";
import EditableList from "../../../components/EditableList";

const {Title} = Typography;
const AddCompanyInfo = ({ticker, tickerList, afterSuccess}) => {
    const [companyInfoForm] = Form.useForm();
    const [list, setList] = useState([]);
    const [lastIdentifier, setLastIdentifier] = useState(0);

    const getLastIdentifierFn = async () => {
        try {
            const {data: {code, message}} = await axiosOrder.get(`${process.env.REACT_APP_SERVER_URL}/company-info/find-last-identifier`);
            if (code === 200) {
                setLastIdentifier(message.Identifier);
            } else throw new Error();
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    useEffect(() => {
        getLastIdentifierFn();
    }, [ticker]);

    const onAddSubmit = async (values) => {

        let companyInfo = tickerList?.find(item => item.ticker === ticker);

        let companyToAffList = [{
            Name: values.Name,
            AffiliatedCompany: companyInfo?.StockName,
            Position: values.Position,
            Identifier: values.Identifier,
        }];

        let finalData = {
            ...values,
            Company: companyInfo?.StockName,
            others: list.map(item => {
                return {
                    ...item,
                    Name: values.Name,
                    Identifier: values.Identifier
                }
            }).concat(companyToAffList)
        }

        try {
            const {data: {code, message: restMessage}} = await axiosOrder.post(`${process.env.REACT_APP_SERVER_URL}/company-info/create`, finalData);
            if (code === 200) {
                message.success(restMessage, 5);
                companyInfoForm.resetFields();
                await afterSuccess();
            } else throw new Error();
        } catch (e) {
            if (e.response && e.response.data) {
                message.error(e.response.data?.message)
                return;
            }
            message.error("Cannot add data. Try again by checking value");
        }
    }

    useEffect(() => {
        companyInfoForm.setFieldsValue({Tkr: ticker});
    }, [ticker]);

    const onEditableChange = (data) => {
        setList(data);
    };
    return (
        <div>
            {lastIdentifier > 0 &&
                <Title level={5}>Latest Identifier: <span
                    style={{color: 'green', fontWeight: "bold"}}> {lastIdentifier} </span></Title>}
            <Divider type={'horizontal'}/>
            <Form form={companyInfoForm} title={"Add Company Info"} layout={'vertical'} onFinish={onAddSubmit}>
                <CommonAddModalBox add/>
                <EditableList list={null} tickerList={tickerList} title={"OTHER KNOWN AFFILIATIONS"}
                              onChange={onEditableChange} deletable editable/>
                <Divider type={'horizontal'} dashed/>
                <Button type={'primary'} htmlType={'submit'}>Add</Button>
            </Form>
        </div>
    );
};

AddCompanyInfo.propTypes = {};

export default AddCompanyInfo;
