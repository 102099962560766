import React from 'react'
import './Notfound.css';

function NotFound() {
    return (
        <div className="not-found body">
            <main>
                <section>
                    <span>404</span>
                    <p>The requested path could not be found</p>
                </section>
            </main>
        </div>
    )
}

export default NotFound


