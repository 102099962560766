import React, {useEffect, useState} from 'react';
import CustomTable from '../../../components/PaisaiPaisa/common/GenericTable';
import AdminHeader from '../../../components/PaisaiPaisa/common/adminHeader';
import {Button, DatePicker, Form, message, Select, Tabs} from 'antd';
import axios from "axios";
import {getHeader} from "../../../methods/actions";
import FixedDepositTable from "../../../components/PaisaiPaisa/common/GenericTable/fixedDepositTable";
import sideBarItems from "../../../components/SideBar/sideBarItems";
import SideBarLayout from "../../../components/SideBar";

const {TabPane} = Tabs;
const {MonthPicker} = DatePicker;

const FixedDeposit = () => {
    const [fdNormal, setFdNormal] = useState([]);
    const [recurringFd, setRecurringFd] = useState([]);
    const [promotionalFd, setPromotionalFd] = useState([]);
    const [company, setCompany] = useState(null);
    const [others, setOthers] = useState(null);
    const [response, setResponse] = useState(null);
    const [dropDownData, setDropDownData] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    // const [paramDate, setParamDate] = useState(null);
    const [fdType, setFdType] = useState('normal_fixed_deposit');
    const header = getHeader();


    useEffect(() => {
        getBankList().then(r => console.log('r', r));
    }, []);

    const getBankList = async () => {
        try {
            const bankList = await axios.get(`${process.env.REACT_APP_SERVER_URL}/paisai-paisa/bank-lists`, header);
            setDropDownData(bankList.data.message)
            setInitialValues({company: bankList.data.message[0].companyName})
        } catch (e) {
            console.log(e)
        }
    }

    const options = {
        editable: {
            input: {
                columns: ['interestRate'],
            },
        },
    };

    const postData = async (result) => {

        try {
            const submitData = {
                formData: result,
                others: others
            }
            const postReponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/paisai-paisa/fd-rates/update`, submitData, header)
            if(postReponse.data.success){
                message.success(postReponse.data.message);
            }else{
                message.error(postReponse.data.message);
            }
        } catch (e) {
            console.log(e);
            message.error(e.message);
        }
    }


    const getTableData = async (company) => {

        try {
            const response = await axios.get(process.env.REACT_APP_SERVER_URL + `/paisai-paisa/fd-rates?company=${company}`, header);
            setCompany(company);
            setResponse(response);
            setFdNormal(response.data.message.fdNormal);
            setPromotionalFd(response.data.message.promotionalFd);
            setRecurringFd(response.data.message.recurringFd);
            // setTableData(response.data.message);
        } catch (e) {
            console.log('e', e);
        }
    }

    const MonthChange = (date, dateString) => {
        const others = {
            sector: 'CB',
            company: company,
            ApplicableFrom: dateString,
            fdType
        }
        setOthers(others);
    }

    const onFinish = async (values) => {
        await getTableData(values.company);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed', errorInfo);
    }

    const callback = (key) => {
        console.log(key);
        setFdType(key);
    };

    const items = [
        {
            label: 'NORMAL FIXED DEPOSIT',
            key: 'normal_fixed_deposit',
            children: <CustomTable datas={fdNormal} options={options} postData={postData}/>
        },
        {
            label: 'RECURRING FIXED DEPOSIT',
            key: 'recurring_fixed_deposit',
            children: <CustomTable datas={recurringFd} options={options} postData={postData}/>
        },
        {
            label: 'PROMOTIONAL FD',
            key: 'promotional_fixed_deposit',
            children: <CustomTable datas={promotionalFd} options={options} postData={postData}/>
        },
    ]
    console.log('initialValues', initialValues);
    return (
        <SideBarLayout siderItems={sideBarItems.paisaiPaisa}>

        <AdminHeader>
                FD RELATED PRODUCTS
            </AdminHeader>
            {Object.keys(initialValues).length !== 0 &&
                <Form
                    layout='inline'
                    className={'mb-2 d-flex'}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={initialValues}
                >
                    <div className={'d-flex mr-3'}>
                        <Form.Item label={'CHOOSE COMPANY'} className={'d-flex mr-5'}
                                   name={`company`}
                                   rules={[{required: true, message: 'Please select company'}]}
                        >
                            <Select
                                showSearch
                                style={{minWidth: 200}}
                                optionFilterProp='children'
                                // onChange={industryChange}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {dropDownData.map((item, index) =>
                                    (
                                        <Select.Option key={index}
                                                       value={item?.companyName}>{item?.companyName}</Select.Option>
                                    ),
                                )}
                            </Select>
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Button htmlType={'submit'}
                                style={{background: '#FFCE00', paddingInline: '20px', fontWeight: 600}}>VIEW</Button>
                    </Form.Item>
                </Form>
            }
            {response &&
                <>
                    <Tabs defaultActiveKey={'1'} onChange={callback}>
                        <TabPane tab={'NORMAL FIXED DEPOSIT'} key={'normal_fixed_deposit'}>
                            {fdNormal &&
                                <FixedDepositTable tableData={fdNormal} monthChange={MonthChange} postData={postData}/>
                            }
                        </TabPane>
                        <TabPane tab={'RECURRING FIXED DEPOSIT'} key={'recurring_fixed_deposit'}>
                            {recurringFd &&
                                <FixedDepositTable tableData={recurringFd} monthChange={MonthChange} postData={postData}/>
                            }
                        </TabPane>
                        <TabPane tab={'PROMOTIONAL FD'} key={'promotional_fixed_deposit'}>
                            {promotionalFd &&
                                <FixedDepositTable tableData={promotionalFd} monthChange={MonthChange} postData={postData}/>
                            }
                        </TabPane>
                    </Tabs>
                </>
            }
        </SideBarLayout>
    );
};

export default FixedDeposit;
