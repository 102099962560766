import {Button, DatePicker, Form, Select} from 'antd';
import React, {useState} from 'react';
import './style.css';
import axios from "axios";
import {getHeader} from "../../../../methods/actions";

const {MonthPicker} = DatePicker;


// eslint-disable-next-line flowtype/no-types-missing-file-annotation
const AdminForm = ({form, dropDownData, setResult, date, url}) => {
    const header = getHeader();
    const [paramDate, setParamDate] = useState(null);

    const getTableData = async (urlParam, others) => {

        try {
            const response = await axios.get(process.env.REACT_APP_SERVER_URL + `/paisai-paisa` + url + urlParam + `&ApplicableFrom=${paramDate}`, header);
            const result = {
                formData: response.data.message,
                others
            }
            setResult(result);
        } catch (e) {
            console.log('e', e);
        }
    }

    let urlParams = [];
    let urlValue = [];

    const MonthChange = (date, dateString) => {
        setParamDate(dateString);
    }

    const onFinish = (values) => {
        urlParams = [];
        urlValue = [];
        for (const property in values) {
            urlParams.push(`${property}=${encodeURIComponent(values[property])}`)
            urlValue.push({ key :[property], value: values[property]});
        }
        const others = Object.fromEntries( urlValue.map(item => [item.key, item.value]));
        getTableData(urlParams[0], others).then(r => console.log(r));
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed', errorInfo);
    }

    const dropDownLabel = Object.keys(dropDownData)[0];
    const dropDownOptions = Object.values(dropDownData)[0];

    const initialValues = {
        [dropDownLabel]: dropDownOptions[0],
    }
    return (
        <Form
            layout='inline'
            className={'mb-2 d-flex'}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={initialValues}
        >
            <div className={'d-flex mr-3'}>
                <Form.Item label={dropDownLabel.toUpperCase()} className={'d-flex mr-5'}
                           name={`${dropDownLabel}`}
                           rules={[{required: true, message: 'Please select industry'}]}
                >
                    <Select
                        showSearch
                        style={{width: 200}}
                        optionFilterProp='children'
                        // onChange={industryChange}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {dropDownOptions.map((item, index) =>
                            (
                                <Select.Option key={index} value={item}>{item}</Select.Option>
                            ),
                        )}
                    </Select>
                </Form.Item>
                {date &&
                    <Form.Item label={'DATE'} className={'d-flex '}
                               name='ApplicableFrom'
                               rules={[
                                   {required: true, message: 'Please select Date'},
                               ]}>
                        <MonthPicker name={'monthpicker'}
                                     onChange={MonthChange}
                        />
                    </Form.Item>
                }
            </div>
            <Form.Item>
                <Button htmlType={'submit'}
                        style={{background: '#FFCE00', paddingInline: '20px', fontWeight: 600}}>VIEW</Button>
            </Form.Item>
        </Form>
    );
};

export default AdminForm;
