import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider} from 'react-redux';
import store from './redux/store/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.less";

if (process.env.NODE_ENV !== "development") {
    console.log = function () {
    };
}

ReactDOM.render(
  <Provider store={store}>
    <App />
    </Provider>,
  document.getElementById('root')
);
