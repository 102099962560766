// import {addBlog} from '@/api/fetch'
// import InputFieldWrapper from '@/components/InputFieldWrapper'
// import Editor from '@/components/TextEditor/src/Editor'
// import {UploadAndPreviewFile} from '@/components/UploadFile'
import {Col, DatePicker, Form, Input, message, Row} from 'antd'
import {useState, useEffect} from 'react'
import Editor from "../../TextEditor/Editor";
import moment from "moment";
import axios from "axios";
import '../../../styles/BlogEditorStyles.css';

export default function CreateBlogModel({selectedEvent, getBlogs}) {

    const [blogContent, setBlogContent] = useState({blogContent: ''})
    const [imageName, setImageName] = useState(selectedEvent.blogImageFile || '')

    useEffect(() => {
        if (Object.keys(selectedEvent).length > 0) {
            formController.setFieldsValue({
                ...selectedEvent,
                blogDate: moment(selectedEvent.blogDate)
            })
        }
    }, [selectedEvent])

    const [formController] = Form.useForm()

    const onFormFinish = async (data) => {
        try {
            let finalData = {
                ...data,
                blogContent: blogContent.blogContent,
                blogDate: moment(data.blogDate).toISOString(),
                blogImageFile: imageName
            }
            console.log('finalData', finalData);
            // const response = await axios({
            //     method: "POST",
            //     url: `${process.env.REACT_APP_SERVER_URL}/admin/blogs/add`,
            //     finalData,
            // });
            // if (response.data.success) {
            //     message.success(response.data.message);
            //     await getBlogs()
            // }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                message.error(error.response?.data.message);
            } else {
                console.log(error);
            }
        }
    }

    return (
        <Form form={formController} onFinish={onFormFinish}>
            <div id='blog-id'>
                <Form.Item name={'id'}>
                    <Input size='large' placeholder='id'/>
                </Form.Item>
            </div>
            <Row gutter={[48, 0]}>
                <Col span={24}>
                    <p>Blog Title</p>
                    <Form.Item
                        name={'title'}
                        rules={[{required: true, message: 'Title Cannot be Empty'}]}
                    >
                        <Input size='large' placeholder='Blog Title'/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[48, 0]}>
                <Col span={24}>
                    <p>Blog Short Description</p>
                    <Form.Item
                        name={'description'}
                        rules={[
                            {required: true, message: 'Description Cannot be Empty'}
                        ]}
                    >
                        <Input size='large' placeholder='Blog Short Discription'/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[48, 0]}>
                {/*  <Col span={4}>*/}
                {/*      <p>Upload Image</p>*/}
                {/*      <Form.Item name={'blogImageFile'}>*/}
                {/*          /!* <UploadAndPreviewFile*/}
                {/*  setImageName={setImageName}*/}
                {/*  selectedEvent={selectedEvent}*/}
                {/*/> *!/*/}
                {/*          <UploadAndPreviewFile*/}
                {/*              setImageName={setImageName}*/}
                {/*              imageName={imageName}*/}
                {/*          />*/}
                {/*      </Form.Item>*/}
                {/*  </Col>*/}
                <Col span={12}>
                    <p>Blog Writer Name</p>
                    <Form.Item
                        name={'blogWriterName'}
                        rules={[
                            {required: true, message: 'Blog Writer Name Is Required'}
                        ]}
                    >
                        <Input size='large' placeholder='Blog Writer Name'/>
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <p>Blog Date</p>
                    <Form.Item
                        name={'blogDate'}
                        rules={[{required: true, message: 'Blog Date Is Required'}]}
                    >
                        <DatePicker
                            className='w-full'
                            size='large'
                            placeholder='Blog Date'
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[48, 0]}>
                <Col span={24}>
                    <p>Blog Content</p>
                    <Form.Item name={'blogContent'}>
                        <Editor
                            className='mt-4 rounded-md border'
                            selectedEvent={selectedEvent}
                            setBlogContent={setBlogContent}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}