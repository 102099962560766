import CardLink from "../../components/cards";
import "./styles.css";
import sideBarItems from "../../components/SideBar/sideBarItems";
import SideBarLayout from "../../components/SideBar";

const FeatureFlag = () => {
  const featureItems = [
    {
      title: "Admin Panel",
      description: "Flowvity Admin Panel",
      link: "/feature-flag/flowvity/admin-panel",
    },
    {
      title: "Website",
      description: "Flowvity company website",
      link: "/feature-flag/flowvity/website",
    },
    {
      title: "Bpmn Panel",
      description: "Flowvity User management",
      link: "/feature-flag/flowvity/bpmn-panel",
    },
    {
      title: "App Panel",
      description: "Flowvity Appapi services",
      link: "/feature-flag/flowvity/appapi",
    },
  ];
  return (
    <>
      <SideBarLayout siderItems={sideBarItems.featureFlag}>
        <div className={"feature-flowvity-cards"}>
          {featureItems.map((item, index) => (
            <CardLink
              key={index}
              title={item.title}
              description={item.description}
              linkTo={item.link}
            />
          ))}
        </div>
      </SideBarLayout>
    </>
  );
};

export default FeatureFlag;
