import {RiLineChartLine, RiLuggageDepositLine, RiMoneyDollarCircleLine} from "react-icons/ri";
import {MdOutlineSavings} from "react-icons/md";
import {ImBlogger} from "react-icons/im";



function getItem(
    label,
    key,
    icon,
    children,
    type,
) {
    return {
        key,
        icon,
        children,
        label,
        type,
    }
}


const sideBarItems = {
    paisaiPaisa:[
        getItem('Base Rate', '/paisai-paisa/base-rate', <RiLineChartLine/>),
        getItem('Loan Rate', '/paisai-paisa/loan-rate', <RiMoneyDollarCircleLine/>),
        getItem('Fixed Deposit', '/paisai-paisa/fixed-deposit', <RiLuggageDepositLine/>),
        getItem('Saving Rate', '/paisai-paisa/saving-rate', <MdOutlineSavings/>),
        getItem('Blogs', '/paisai-paisa/blogs', <ImBlogger/>),
    ],
    featureFlag:[
        getItem('Flowvity', '/feature-flag/flowvity', <RiLineChartLine/>),
    ]
};

export default sideBarItems;