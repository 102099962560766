import { Input, Form, Button, Table } from "antd";
import { Fragment, useState, useEffect } from "react";
import MaterialAppBar from "components/MaterialAppBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import axiosOrder from "axios-order";
import { getHeader } from "methods/actions";

import { message, DatePicker } from "antd";
import moment from "moment";

import "./historicalNAV.css";

const HistoricalNAV = () => {
  const [dataSource, setDataSource] = useState([]);
  const [updatedDataSource, setUpdatedDataSource] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const [form] = Form.useForm();

  const header = getHeader();
  const [messageApi, contextHolder] = message.useMessage();
  const buttonVar = document.getElementById("saveButtonId");

  const onFinish = (values) => {
    console.log("Fund submitted", values);

    getHistNavApi(values?.fundName);

    // NumberList();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed", errorInfo);
  };

  const handleInputChange = (value, record) => {
    const updatedRecord = updatedDataSource.map((ds) => {
      if (ds.ticker === record.ticker) {
        let stringValue = value.toString().replace(/[^0-9.]/g, "");
        const decimalCount = (stringValue.split(".")[1] || "").length;
        if (decimalCount > 2) {
          return ds;
        }

        return {
          ...ds,
          NAV: stringValue,
        };
      }
      return ds;
    });

    setUpdatedDataSource(updatedRecord);
  };

  const onSubmit = () => {
    messageApi.open({
      type: "success",
      content: "Form submitted successfully",
    });

    const filteredData = updatedDataSource.filter(
      (ds) => ds.NAV && ds.NAV != ""
    );

    const apiCalls = filteredData.map((item) => {
      let payload = {
        stock_full_name: item.stock_full_name,
        ticker: item.ticker,
        Frequency: item.Frequency,
        Date: selectedDate,
        nav: item.NAV,
      };
      return axiosOrder.post(`/nepse/hist-nav/add-weekly-nav`, payload, header);
    });
    Promise.all(apiCalls)
      .then((response) => {
        console.log("success--", response);
      })
      .catch((e) => {
        console.log("error--", e);
        message.error("failed to fetch Historical Net Asset Value");
      });

    setInputValue("");
    // setUpdatedDataSource([]);
    setUpdatedDataSource((prevDataSource) => {
      return prevDataSource.map((item) => ({
        ...item,
        NAV: "",
      }));
    });
    form.resetFields();
  };

  const handleDateChange = (date) => {
    setSelectedDate(date ? date.format("YYYY-MM-DD") : null);

    if (date) {
      buttonVar.style.background = "#0e6efd";
    }
  };

  const columns = [
    {
      title: "Fund Name",
      dataIndex: "stock_full_name",
      key: "fundName",
    },
    {
      title: "Ticker",
      dataIndex: "ticker",
      key: "Ticker",
    },
    {
      title: "Date",
      dataIndex: "weekly_nav_date",
      key: "Date",
    },
    {
      title: "Frequency",
      dataIndex: "Frequency",
      key: "Frequency",
    },
    {
      title: "NAV",
      dataIndex: "nav",
      key: "NAV",
    },
    {
      title: "New Nav",
      key: "New Nav",
      render: (record) => (
        <input
          className="navInput"
          value={record?.NAV || ""}
          onChange={(e) => handleInputChange(e.target.value, record)}
        />
      ),
    },
  ];

  const getHistNavApi = (fundName) => {
    axiosOrder
      .get(`/nepse/hist-nav/get-weekly-nav?fundName=${fundName}`, header)
      .then((response) => {
        setDataSource(response.data.message);
      })
      .catch((e) => {
        console.log("error--", e);
        message.error("failed to fetch Historical Net Asset Value");
      });
  };

  useEffect(() => {
    const valueForDataSource = dataSource.map((element) => {
      return { ...element, Frequency: "weekly" };
    });

    setUpdatedDataSource(valueForDataSource);
  }, [dataSource]);

  const onCompleteFailed = (errorInfo) => {
    messageApi.open({
      type: "error",
      content: "There was an error in submitting the form",
    });
    console.log("Failed", errorInfo);
  };

  return (
    <Fragment>
      <MaterialAppBar />
      <div className="containerDiv">
        <div className="head">
          <h1>Add Historical NAV</h1>
          <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <div className="wrapperFund">
              <Form.Item
                label="Fund"
                className="labelAndInput"
                name="fundName"
                rules={[{ required: true, message: "Please input Fund" }]}
              >
                <div className="input-container">
                  <Input
                    placeholder="&nbsp;&nbsp;&nbsp;&nbsp; Search Keyword"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />

                  <div className="icon-container">
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      id="magnifyingGlassId"
                    />
                  </div>
                </div>
              </Form.Item>

              <Form.Item>
                <Button
                  htmlType={"submit"}
                  style={{
                    background: "#0e6efd",
                    fontWeight: 600,
                  }}
                >
                  Go
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
        {updatedDataSource.length !== 0 && (
          <>
            <Table
              id="data-table"
              dataSource={updatedDataSource}
              columns={columns}
              scroll={{ y: 350 }}
              pagination={false}
              className="histNavTable"
            />

            <div className="foot">
              {contextHolder}
              <Form
                form={form}
                onFinish={onSubmit}
                onFinishFailed={onCompleteFailed}
              >
                <Form.Item
                  className="dateFormItem"
                  name="dateFormItem"
                  rules={[{ required: true, message: "Please input date" }]}
                >
                  <DatePicker
                    onChange={handleDateChange}
                    className="dateClass"
                    allowClear
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    id="saveButtonId"
                    htmlType={"submit"}
                    style={{
                      background: "#757575",
                      fontWeight: 600,
                      borderRadius: "8px",
                    }}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
};

export default HistoricalNAV;
