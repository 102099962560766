import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from "prop-types";

import {Col, Form, Input, Row, Select, Typography} from "antd";
import {debounce} from "lodash";

import axiosOrder from "../../../../axios-order";

const {Text} = Typography;
const {Option} = Select;

const TypeList = ['Auditor',
    'Management',
    'Board',
    'Managements',
    'Share Registrar'
]
const CommonAddModalBox = ({edit, isLoading}) => {

    const [isFound, setIsFound] = useState([]);
    const [name, setName] = useState('');
    const [isMounted, setIsMounted] = useState(false);

    useEffect(async () => {
        if (isMounted && name) {
            await debounceLoadName(name);
        }
    }, [name]);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    const onApiSearchUsername = async (name) => {
        try {
            const {data: {code, message}} = await axiosOrder.get("/company-info/find-username",
                {
                    params: {
                        name,
                        getList: false
                    }
                });
            if (code === 200) {
                setIsFound(message.message);
            } else throw new Error();
        } catch (e) {
            console.log(e);
            setIsFound([]);
        }
    }

    const debounceLoadName = useCallback(debounce(onApiSearchUsername, 500), []);

    const onSearchUserName = async (event) => {
        const name = event.target.value;
        setName(name);
    };

    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col md={6}>
                    <Form.Item label={'Identifier'} name={'Identifier'} rules={[{
                        required: true,
                        message: "This Is Required Field"
                    }]}>
                        <Input placeholder={"Enter Identifier Name"} disabled={edit}/>
                    </Form.Item>
                </Col>
                <Col md={6}>
                    <Form.Item label={'Ticker'} name={'Tkr'}>
                        <Input placeholder={'Enter Ticker Name'} disabled/>
                    </Form.Item>
                </Col>
                <Col md={6}>
                    <Form.Item label={'Year'} name={'Year'}>
                        <Input placeholder={'Enter Year'}/>
                    </Form.Item>
                </Col>
                <Col md={6}>
                    <Form.Item label={'Type'} name={'Type'}>
                        <Select placeholder={'Enter Type'}>
                            {TypeList.map(item => <Option key={item} value={item}>{item}</Option>)}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col md={12}>
                    <Form.Item label={'Name'} name={'Name'} extra={(isFound && !edit) ?
                        <Text type={'danger'}>{isFound}</Text> : null}>
                        <Input onChange={onSearchUserName}
                               placeholder={"Enter User Name"}/>
                    </Form.Item>
                </Col>
                <Col md={12}>
                    <Form.Item label={'Position'} name={'Position'}>
                        <Input placeholder={"Enter Position Name"}/>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item label={'Qualification'} name={'Qualification'}>
                <Input.TextArea placeholder={'Enter Qualification Here'} showCount rows={6}/>
            </Form.Item>
        </div>
    );
};

CommonAddModalBox.propTypes = {
    edit: PropTypes.bool,
};

export default CommonAddModalBox;
