import FeatureFlag from "../containers/featureFlag";
import FlowvityAdminPanel from "../containers/featureFlag/adminPanel";
import FlowvityBpmnPanel from "../containers/featureFlag/bpmnPanel";

const featureFlagRouter = [
  {
    title: "Flowvity Feature Flag",
    url: "/feature-flag/flowvity",
    component: FeatureFlag,
  },
  {
    title: "Flowvity Admin Panel",
    url: "/feature-flag/flowvity/admin-panel",
    component: FlowvityAdminPanel,
  },
  {
    title: "Flowvity Website Features",
    url: "/feature-flag/flowvity/website",
    component: FlowvityBpmnPanel,
  },
  {
    title: "Flowvity BPMN Panel",
    url: "/feature-flag/flowvity/bpmn-panel",
    component: FlowvityBpmnPanel,
  },
  {
    title: "Flowvity App Panel",
    url: "/feature-flag/flowvity/appapi",
    component: FlowvityBpmnPanel,
  },
];

export default featureFlagRouter;
