// import { deleteBlog, getAllBlogs, verifyCookie } from "@/api/fetch";
// import CreateBlogModel from "@/components/Admin/Blog/CreateBlogModel";

import {Button, Modal, Table} from "antd";
import {useEffect, useState} from "react";
import SideBarLayout from "../../../components/SideBar";
import sideBarItems from "../../../components/SideBar/sideBarItems";
import CreateBlogModel from "../../../components/PaisaiPaisa/Blog/CreateBlogModel";
import axios from "axios";
import {getHeader} from "../../../methods/actions";
import moment from "moment";

export default function PaisaiPaisaBlog() {
    const [selectedEvent, setSelectedEvent] = useState({});
    const [dataSource, setDataSource] = useState([]);
    const [showModel, setShowModel] = useState(false);

    const header = getHeader();

    const getBlogs = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/paisai-paisa/all-blogs`, header);
            if (response?.data.success) {
                setDataSource(response?.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getBlogs();
    }, []);

    const columns = [
        {
            title: "Blog",
            dataIndex: "title",
        },
        {
            title: "Blog Publish Date",
            dataIndex: "blogDate",
            render(_, record) {
                return <p>{moment(_).format("DD/MM/YYYY")}</p>;
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            render(_, record) {
                return (
                    <div className="flex flex-wrap gap-4">
                        <Button
                            onClick={() => {
                                setSelectedEvent(record);
                                setShowModel(true);
                            }}
                        >
                            Edit
                        </Button>
                        <Button
                            onClick={async () => {
                                console.log(record);
                                // await deleteBlog(record.id);
                                await getBlogs();
                            }}
                            danger
                            type="primary"
                        >
                            Delete
                        </Button>
                    </div>
                );
            },
        },
    ];
    return (
        <SideBarLayout siderItems={sideBarItems.paisaiPaisa}>
            <main>
                <div className="">
                    <div className="mb-8 flex justify-end">
                        <Button
                            type="primary"
                            size="large"
                            onClick={() => {
                                setSelectedEvent({});
                                setShowModel(true);
                            }}
                        >
                            <span className="px-8">NEW BLOG</span>
                        </Button>
                    </div>
                    <Modal
                        destroyOnClose
                        open={showModel}
                        width={"80%"}
                        onCancel={() => setShowModel(false)}
                        footer={null}
                    >
                        <CreateBlogModel selectedEvent={selectedEvent} getBlogs={getBlogs}/>
                    </Modal>
                    <Table
                        dataSource={dataSource}
                        tableLayout="fixed"
                        columns={columns}
                        pagination={{pageSize: 10}}
                    />
                </div>
            </main>
        </SideBarLayout>
    );
}