import {
    Button, Dropdown, Layout, Menu
    // MenuProps, Space
} from 'antd';
import {useState} from 'react';
import {FaPercentage} from 'react-icons/fa';
import {
    RiMenuFoldFill,
    RiMenuUnfoldFill,
    RiMoneyDollarCircleLine,
    RiLuggageDepositLine,
    RiLineChartLine,
} from 'react-icons/ri';
import { MdOutlineSavings } from 'react-icons/md';
import {useHistory} from 'react-router-dom';
import './styles.css';
import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import {IoArrowBackCircle} from "react-icons/io5";


const {Header, Sider, Content} = Layout;
const {SubMenu} = Menu;

// eslint-disable-next-line flowtype/no-types-missing-file-annotation
const SideBarLayout = ({children, siderItems}) => {

    const [collapsed, setCollapsed] = useState(false);
    const router = useHistory();

    const onMenuClick = (Link) => {
        router.push({pathname: Link.key});
    };

    return (
        <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed}>
                <Menu
                    onClick={onMenuClick}
                    theme='dark'
                    mode='inline'
                    defaultSelectedKeys={[`${router.location.pathname}`]}
                    items={siderItems}
                />
            </Sider>
            <Layout>
                <Header style={{background: '#fff', padding: 0,  display:"flex", justifyContent:"space-between"}}>
                    <Button
                        type={'text'}
                        icon={collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64
                        }}
                    />
                    <div className='back-button-container'>

                        <Button
                            type={'text'}
                            icon=<IoArrowBackCircle className='back-button'/>
                        onClick={() => router.push('/manualupdate')}
                        style={{
                        fontSize: '16px',
                        width: 64,
                        height: 64
                    }}
                        />
                    </div>
                </Header>
                <Content
                    style={{
                        margin: '0 16px',
                        padding: '0 24px',
                        background: '#f0f2f5',
                        minHeight: 280,
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
};

export default SideBarLayout;
