import moment from "moment";
import { Form, DatePicker, Select, Input, Space, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import axiosOrder from "axios-order";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";

import { getHeader } from "methods/actions";
import MaterialAppBar from "components/MaterialAppBar";
import "./financialUpdates.css";

const current_date = moment();

const useForm = () => {
  const [form] = Form.useForm();
  return form;
};

const FinancialUpdates = () => {
  const form = useForm();
  const [latestFy, setLatestFy] = useState("");
  const [selectedTkr, setSelectedTkr] = useState([]);
  const [tkr, setTkr] = useState([]);
  let history = useHistory();
  // const [companyName, setCompanyName] = useState([]);

  const handleChange = (value) => {
    setSelectedTkr(value);
  };
  console.log("selectedTkr--", selectedTkr);

  const displayAddUpdates = (event) => {
    event.preventDefault();
    history.push("/addUpdate");
  };

  const detailsList = [
    {
      label: "1st Quarter Unaudited Financial Result",
      value: "1st Quarter Unaudited Financial Result",
    },
    {
      label: "2nd Quarter Unaudited Financial Result",
      value: "2nd Quarter Unaudited Financial Result",
    },
    {
      label: "3rd Quarter Unaudited Financial Result",
      value: "3rd Quarter Unaudited Financial Result",
    },
    {
      label: "4th Quarter Unaudited Financial Result",
      value: "4rd Quarter Unaudited Financial Result",
    },
  ];

  const header = getHeader();

  useEffect(() => {
    getStockSymbol();
    getCurrentFiscalYear();
  }, []);

  useEffect(() => {
    form.setFieldValue("FiscalYear", latestFy);
  }, [latestFy]);

  const getStockSymbol = () => {
    axiosOrder
      .get(`/company-info/stock-symbol`, header)
      .then((response) => {
        setTkr(response.data.message);
      })
      .catch((e) => {
        console.log("error--", e);
        message.error("failed to fetch stock symbol");
      });
  };

  const getCurrentFiscalYear = () => {
    axiosOrder
      .get(`/fiscal-year/current`, header)
      .then((response) => {
        setLatestFy(response.data.message.englishFiscalYear);
      })
      .catch((e) => {
        console.log("error", e);
        message.error("failed to fetch current fiscal year.");
      });
  };

  const onFinish = (values) => {
    const prependedList = latestFy + " " + values.DetailsType;
    let inputDate = moment().format("YYYY-MM-DD");

    if (values.InputDate) {
      inputDate = values.InputDate.format("YYYY-MM-DD");
    }

    if (selectedTkr.length === 0) {
      console.log("Please select at least one ticker symbol.");
      return; // Do not proceed with form submission
    }

    selectedTkr.forEach((item) => {
      const tkrCompany = tkr.find((a) => {
        return a.ticker === item;
      });

      const form_values = {
        ...values, // Create a new form_values object for each instance
        Time: null,
        InputDate: inputDate ? inputDate : null,
        EventDate: inputDate ? inputDate : null,
        OpenDate: null,
        CloseDate: null,
        BookCloseDate: null,
        AllotmentDate: null,
        DetailsType: prependedList ? prependedList : null,
        Details: prependedList ? prependedList : null,
        Agenda: prependedList ? prependedList : null,
        InputType: "Financials",
        LastDayNameTransfer: null,
        ShareRegistrar: null,
        Venue: null,
        TKR: item,
        Company: tkrCompany.StockName ? tkrCompany.StockName : null,
      };

      axiosOrder
        .post("/updates/add-update", form_values, header)
        .then(function (response) {
          console.log("response", response);
          if (response.data.code === 200) {
            console.log(
              "Successfully added financial updates.",
              response.config.data
            );
            message.success(response.data.message);
            form.resetFields();
          } else {
            console.error(
              "Failed to add financial updates.",
              response.config.data
            );
            message.error(response.data.message);
          }
        })
        .catch(function (error) {
          console.error("Failed to add financial updates.", error);
          message.error("Failed to add financial updates.");
        });
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <MaterialAppBar />
      <div className="wrapperNavFinancial">
        <FontAwesomeIcon
          icon={faLeftLong}
          size="2xl"
          onClick={displayAddUpdates}
          className="leftLongIcon"
        />
        <h3 className="headingNav"> Financial Updates</h3>
      </div>

      <div>
        <Form
          name="financialUpdates"
          // labelCol={{
          //   span: 13,
          // }}
          // wrapperCol={{
          //   span: 10,
          // }}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{
            FiscalYear: latestFy,
          }}
        >
          <div className="rows">
            <div className="firstRow">
              <div>
                <Form.Item label="InputDate" name="InputDate">
                  <DatePicker
                    defaultValue={current_date}
                    format="YYYY-MM-DD"
                    value={current_date}
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item label="FiscalYear" name="FiscalYear">
                  <Input disabled />
                </Form.Item>
              </div>
              <div>
                <Form.Item label="DetailsType" name="DetailsType">
                  <Select
                    options={detailsList}
                    style={{ width: "300px" }}
                  ></Select>
                </Form.Item>
              </div>
            </div>

            <div className="secondRow">
              <Form.Item label="TKR" name="TKR">
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                >
                  <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: "600px",
                    }}
                    placeholder="Please select"
                    onChange={handleChange}
                    options={
                      tkr.map((a) => {
                        return {
                          label: `(${a.ticker}) ${a.StockName}`,
                          value: a.ticker,
                        };
                      }) || null
                    }
                  />
                </Space>
              </Form.Item>
            </div>
            <div className="submitButton">
              <Form.Item>
                <Button type="primary" htmlType="submit" className="button">
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default FinancialUpdates;
