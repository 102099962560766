import React, {useState} from 'react'
import MaterialSearchBarPlus from '../../components/MaterialSearchBarPlus'
import MaterialUserDetailTable from '../../components/MaterialUserDetailTable'
import MaterialSystemXUserDetailTable from '../../components/MaterialSystemXUserDetailTable'
import MaterialTablePagination from '../../components/MaterialTablePagination'
import SystemXAddUserModel from '../../components/SystemXAddUserModel'
import './UserDetailsTableWithPaginationAndSearch.css'


function UserDetailsTableWithPaginationAndSearch(props) {
    const {rowsPerPage, setRowsPerPage, rows, setPage, page, database, fetchAllDataByOption} = props;
    const [searchValue, setsearchValue] = useState("");
    // console.log(rows);

    const requestSearch = (rows) => {
            if (database === 'npstocks') {
                const filteredRows = rows?.filter((row) =>
                    row?.username?.toLowerCase().includes(searchValue.toLowerCase()) || row?.phone?.includes(searchValue)
                );
                console.log('filteredRows',filteredRows);
            }
            return rows.filter((row) => row.username && row.phone ?
                row?.username?.toLowerCase().includes(searchValue.toLowerCase()) || row?.phone?.includes(searchValue) :
                row?.username?.toLowerCase().includes(searchValue.toLowerCase()) || row?.groupT?.includes(searchValue))
            // return rows.filter((row) => row.username.toLowerCase().indexOf(searchValue) || row.phone.indexOf(searchValue)>-1)
    };

    return (
        <div className="container">
            {
                database === 'systemx' ?
                    <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                        <SystemXAddUserModel database={database} fetchAllDataByOption={fetchAllDataByOption}
                                             name="Add User" variant="primary"/>
                    </div>
                    : <div></div>
            }

            {rows !== "" ?
                <div>
                    <div className="searchBarWrapper">
                        <MaterialTablePagination rows={rows} page={page} setPage={setPage} rowsPerPage={rowsPerPage}
                                                 setRowsPerPage={setRowsPerPage}/>
                        <MaterialSearchBarPlus searchValue={searchValue} setsearchValue={setsearchValue}
                                               database={database}/>
                    </div>
                    <div className="tableWrapper">{
                        database === 'systemx' ?
                            <MaterialSystemXUserDetailTable rowsPerPage={rowsPerPage} page={page}
                                                            rows={requestSearch(rows)} database={database}
                                                            fetchAllDataByOption={fetchAllDataByOption}/>
                            :
                            <MaterialUserDetailTable rowsPerPage={rowsPerPage} page={page} rows={requestSearch(rows)}
                                                     database={database} fetchAllDataByOption={fetchAllDataByOption}/>
                    }
                    </div>
                </div> : <div></div>}
        </div>
    )
}

export default UserDetailsTableWithPaginationAndSearch
