import React, {useEffect, useState} from 'react';
import {Button, Card, Divider, message, Modal, Select, Space, Tooltip, Typography} from "antd";
import {CloseOutlined, DeleteFilled, EditFilled, PlusOutlined} from "@ant-design/icons";
import {isEmpty} from "lodash";

import BackAppBar from "../../components/BackAppBar";
import axiosOrder from "../../axios-order";
import CustomReactTable from "../../components/CustomReactTable/Table/CustomReactTable";
import withLoaderHandler from "../../hoc/withLoaderHandler";
import EditCompanyInfo from "./Forms/EditCompanyInfo";
import AddCompanyInfo from "./Forms/AddCompanyInfo";
import EditAffiliationData from "./Forms/EditAffiliationData";

import './style.css';

const {Title} = Typography;
const {Option} = Select;
const Index = ({loading}) => {

    const [tickerList, setTickerList] = useState([]);
    const [affiliationData, setAffiliationData] = useState([]);

    const [companyTableData, setCompanyTableData] = useState([]);
    const [selectedTicker, setSelectedTicker] = useState(null);

    const [affiliationUpdateData, setAffiliationUpdateData] = useState({});

    // for modal
    const [showCompanyEdit, setCompanyEdit] = useState({});

    const [showAddModal, setShowAddModal] = useState(false);


    useEffect(() => {
        getTickerList();
    }, []);

    const getCompanyInfo = async (ticker) => {
        try {
            const companyInfoData = await axiosOrder.get("/company-info/get", {params: {ticker}});
            setCompanyTableData(companyInfoData.data.message);
            setAffiliationData([]);
        } catch (e) {
            console.log(e);
            message.error(`Cannot load ${ticker} Company Data`);
        }
    };

    const getAffiliationData = async (Identifier) => {
        try {
            const affiliationData = await axiosOrder.get("/company-info/affiliation", {
                params: {identifier: Identifier}
            });
            setAffiliationData(affiliationData.data.message);
        } catch (e) {
            console.log(e);
            message.error(`Cannot load ${Identifier} Affiliation Data`);
        }
    };
    const getTickerList = async () => {
        try {
            const tickerList = await axiosOrder.get("/company-info/stock-symbol");
            setTickerList(tickerList.data.message);
        } catch (e) {
            console.log(e);
            message.error("Cannot Load Data");
        }
    }
    const deleteCompanyIdentifier = (row) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <CloseOutlined/>,
            content: 'Are you sure , you want to delete this Identifier record',
            async onOk() {
                const {data: {code}} = await axiosOrder.delete("/company-info/delete", {
                    params: {
                        identifier: row.Identifier,
                        ticker:selectedTicker
                    }
                });
                if (code === 200) {
                    message.success(`Company Info Identifier ${row.Identifier} deleted Successfully.`);
                    await getCompanyInfo(selectedTicker);
                }
            }
        });
    }

    const deleteAffiliationIdentifier = (row) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <CloseOutlined/>,
            content: 'Are you sure, you want to delete this Affiliation Identifier record',
            async onOk() {
                const {data: {code}} = await axiosOrder.post("/company-info/delete-affiliation", {
                    ...row._original
                });
                if (code === 200) {
                    message.success(`Affiliation Identifier ${row.Identifier} deleted Successfully.`);
                    await getCompanyInfo(selectedTicker);
                }
            }
        });
    };

    const onSelectChange = async (ticker) => {
        await getCompanyInfo(ticker);
        setSelectedTicker(ticker);
    }

    const renderCompanyEditModal = () => {
        return <Modal width={1130} title={"Edit Company info"} footer={null} visible={!isEmpty(showCompanyEdit)}
                      destroyOnClose
                      onCancel={() => setCompanyEdit({})}>
            <EditCompanyInfo form={showCompanyEdit} isLoading={loading} reloadData={() => {
                getCompanyInfo(selectedTicker)
            }}/>
        </Modal>
    }

    const renderCompanyAddModal = () => {
        return <Modal destroyOnClose width={1130} title={"Add Company Info"} footer={null}
                      onCancel={() => setShowAddModal(false)}
                      visible={showAddModal}>
            <AddCompanyInfo ticker={selectedTicker} tickerList={tickerList}
                            afterSuccess={() => getCompanyInfo(selectedTicker)}/>
        </Modal>
    }

    const renderAffiliationEditModal = () => {
        return <Modal destroyOnClose width={1130} title={"Edit Affiliation Info"} footer={null}
                      onCancel={() => setAffiliationUpdateData({})}
                      visible={!isEmpty(affiliationUpdateData)}>
            <EditAffiliationData form={affiliationUpdateData} ticker={selectedTicker} tickerList={tickerList}
                                 afterSuccess={() => getCompanyInfo(selectedTicker)}/>
        </Modal>
    }

    const onApiSearchUsername = async (name) => {
        if (!name || name === '' || name.length === 0) {
            setAffiliationData([]);
            return;
        }
        try {
            const {data: {code, message}} = await axiosOrder.get("/company-info/find-username",
                {
                    params: {
                        name,
                        getList: true
                    }
                });
            if (code === 200) {
                setAffiliationData(message?.list);
            } else throw new Error();
        } catch (e) {
            console.log(e);
            return setAffiliationData([]);
        }
    }

    return (
        <>
            <BackAppBar/>
            <Card className={'space'}>
                <Divider>
                    <Title level={4}>Company Info</Title>
                </Divider>
                <div className={'flex'}>
                    <div className={'side-2'}>
                        <Space>
                            <Title level={5}>Ticker</Title>
                            <Select onChange={onSelectChange} showSearch showArrow
                                    placeholder={"Select Ticker From Here"} style={{width: 250}}>
                                {tickerList.map((item, index) => <Option key={index}
                                                                         value={item.ticker}>{item.ticker}</Option>)}
                            </Select>
                        </Space>
                        <div>
                            <Button type={'primary'} icon={<PlusOutlined/>} onClick={() => {
                                if (selectedTicker) {
                                    setShowAddModal(true)
                                } else {
                                    message.warning("Select One Ticker From Select Box To Continue");
                                }
                            }}>Add New Company Info</Button>
                        </div>
                    </div>
                </div>
                <CustomReactTable isSearchable={true} isDownloadable={false} isUploadVisible={false}
                                  tableOption={{
                                      tableData: companyTableData,
                                      customCell: (cellList) => {
                                          let cellListObj = {};
                                          cellList.forEach(item => {
                                              if (item === 'Identifier') {
                                                  cellListObj[item] = {
                                                      display(row) {
                                                          return <div title={'Clickable'} onClick={async () => {
                                                              await getAffiliationData(row.value);
                                                          }}><b>{row.value}</b></div>
                                                      }
                                                  }
                                              }
                                          });
                                          return cellListObj;
                                      },
                                      buttons: [{
                                          Header: "",
                                          buttonName: "",
                                          accessor: "Delete",
                                          icons: (
                                              <Tooltip color={"red"} placement="left"
                                                       title={"Delete This Identifier ?"}>
                                                  <Button
                                                      danger
                                                      type="primary"
                                                      icon={<DeleteFilled/>}
                                                  />
                                              </Tooltip>
                                          ),
                                          onClick: (row) => {
                                              deleteCompanyIdentifier(row);
                                          },
                                      },
                                          {
                                              Header: "",
                                              buttonName: "",
                                              accessor: "Edit",
                                              icons: (
                                                  <Tooltip color={"blue"} placement="right"
                                                           title={"Edit This Identifier ? "}>
                                                      <Button
                                                          type="primary"
                                                          icon={<EditFilled/>}
                                                      />
                                                  </Tooltip>
                                              ),
                                              onClick: (row) => {
                                                  setCompanyEdit({...row._original, Tkr: selectedTicker});
                                              },
                                          }]
                                  }}
                />

                <>
                    <Divider/>
                    <CustomReactTable isSearchable={true} isDownloadable={false} isUploadVisible={false}
                                      title={"Affiliation Table"}
                                      tableOption={{
                                          tableData: affiliationData,
                                          showSearch: true,
                                          onSearchTable: async (value, _) => {
                                              return await onApiSearchUsername(value);
                                          },
                                          buttons: [
                                        //     {
                                        //       Header: "",
                                        //       buttonName: "",
                                        //       accessor: "Delete",
                                        //       icons: (
                                        //           <Tooltip color={"red"} placement="left"
                                        //                    title={"Delete This Identifier ?"}>
                                        //               <Button
                                        //                   danger
                                        //                   type="primary"
                                        //                   icon={<DeleteFilled/>}
                                        //               />
                                        //           </Tooltip>
                                        //       ),
                                        //       onClick: (row) => {
                                        //           deleteAffiliationIdentifier(row);
                                        //       },
                                        //   },
                                              {
                                                  Header: "",
                                                  buttonName: "",
                                                  accessor: "Edit",
                                                  icons: (
                                                      <Tooltip color={"blue"} placement="right"
                                                               title={"Edit This Identifier ? "}>
                                                          <Button
                                                              type="primary"
                                                              icon={<EditFilled/>}
                                                          />
                                                      </Tooltip>
                                                  ),
                                                  onClick: (row) => {
                                                      setAffiliationUpdateData(row._original);
                                                  },
                                              }]
                                      }}
                    /> </>
                {renderCompanyEditModal()}
                {renderCompanyAddModal()}
                {renderAffiliationEditModal()}
            </Card>
        </>
    );
};

export default withLoaderHandler(Index, axiosOrder, true);
// export default Index;
