import {Table, Select, Button, InputNumber, DatePicker, message} from 'antd';
import '../style.css';
import {useEffect, useRef, useState} from 'react';
import {replaceObject} from "../../../../../methods/actions";

const {MonthPicker} = DatePicker;

const LoanTable = ({tableData, monthChange, postData, showBasedOn = true}) => {

    const [dataSource, setDataSource] = useState([]);
    const [fixedRate, setFixedRate] = useState('');
    const [selectedDate, setSelectedDate] = useState(false);
    const dateRef = useRef(null);

    useEffect(() => {
        setDataSource(tableData);
        setFixedRate('baseRate');
    }, [tableData]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'Name',
            key: 'Name',
            // sorter: (a, b) => a.Name.localeCompare(b.Name),
        }, {
            title: 'Type',
            dataIndex: 'Type',
            key: 'Type',
            // sorter: (a, b) => a.Type.localeCompare(b.Type),
        }, {
            title: 'Rate Type',
            dataIndex: 'RateType',
            key: 'RateType',
        }, {
            title: 'Loan Duration',
            dataIndex: 'LoanDuration',
            key: 'LoanDuration',
            align: 'center'
        }, {
            title: 'Loan Amount',
            dataIndex: 'LoanAmount',
            key: 'LoanAmount',
        }, {
            title: 'Applicable BaseRate',
            dataIndex: 'ApplicableBaseRate',
            key: 'ApplicableBaseRate',
            align: 'center'
        }, {
            title: 'Min',
            dataIndex: 'Min',
            key: 'Min',
            render: (text, record) => (
                <InputNumber value={text} onChange={(value) => handleMinChange(value, record)}/>
            ),
        }, {
            title: 'Max',
            dataIndex: 'Max',
            key: 'Max',
            render: (text, record) => (
                <InputNumber value={text} onChange={(value) => handleMaxChange(value, record)} disabled={showBasedOn}/>
            ),
        }, {
            title: 'Min Rate',
            dataIndex: 'MinRate',
            key: 'MinRate',
            render: (text) => (
                <InputNumber value={text} disabled/>
            ),
        }, {
            title: 'Max Rate',
            dataIndex: 'MaxRate',
            key: 'MaxRate',
            render: (text) => (
                <InputNumber value={text} disabled/>
            ),
        },
    ];

    const handleMinChange = (value, record) => {
        const newData = [...dataSource];
        const updatedRecord = {...record, Min: Number(value)};
        if(showBasedOn){
            updatedRecord.Max = Number(value);
        }
        if (fixedRate === 'baseRate') {
            updatedRecord.MinRate = Number(value) + Number(record.ApplicableBaseRate);
        } else {
            updatedRecord.MinRate = value;
        }
        replaceObject(newData,"key",record.key,updatedRecord);
        setDataSource(newData);
    };

    const handleMaxChange = (value, record) => {
        const newData = [...dataSource];
        const updatedRecord = {...record, Max: value};
        if (fixedRate === 'baseRate') {
            updatedRecord.MaxRate = Number(value) + Number(record.ApplicableBaseRate);
        } else {
            updatedRecord.MaxRate = value;
        }
        replaceObject(newData,"key",record.key,updatedRecord);
        setDataSource(newData);
    };

    const onSubmit = () => {
        if (selectedDate) {
            postData(dataSource);
        } else {
            message.error('Please select date');
            dateRef.current.focus();
        }
    };

    const handleChange = (value) => {
        setFixedRate(value);
        const newData = [...dataSource];
        const updatedData = [];
        if (value === 'baseRate') {
            newData.forEach((record) => {
                const MinRate = Number(record.Min) + Number(record.ApplicableBaseRate);
                const MaxRate = Number(record.Max) + Number(record.ApplicableBaseRate);
                const updatedRecord = {...record, MinRate, MaxRate};
                updatedData.push(updatedRecord);
            });
        } else {
            newData.forEach((record) => {
                const MinRate = Number(record.Min);
                const MaxRate = Number(record.Max);
                const updatedRecord = {...record, MinRate, MaxRate};
                updatedData.push(updatedRecord);
            });
        }
        setDataSource(updatedData);
    };

    const dateSelected = (date, dateString) => {
        monthChange(date, dateString);
        setSelectedDate(true)
    }

    return (
        <>
            {
                showBasedOn &&
                <div className={'d-flex justify-content-end align-items-baseline'}>
                    <p className={'mx-4 fw-bold'}>Fixed Rate based on</p>
                    <Select defaultValue={showBasedOn ? 'baseRate' : 'numberOnly'} onChange={handleChange}
                            style={{minWidth: '15%'}}>
                        <Select.Option value={'baseRate'}>
                            Base Rate
                        </Select.Option>
                        <Select.Option value={'numberOnly'}>
                            Number Only
                        </Select.Option>
                    </Select>
                </div>
            }
            <Table dataSource={dataSource} columns={columns} scroll={{y: 350}} pagination={false}/>
            <div className={'d-flex justify-content-between my-4'}>
                <div className={'d-flex align-items-baseline justify-content-end'}>
                    <p className={'mx-4 fw-bold'}>
                        NEW DATA APPLICABLE FOR
                    </p>
                    <MonthPicker onChange={dateSelected} ref={dateRef}/>
                </div>
                <Button onClick={onSubmit} type={'primary'} style={{background: '#2D3648'}}> Save</Button>
            </div>
        </>
    );
};

export default LoanTable;
