import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, Input, message, Row, Select} from "antd";
import axiosOrder from "../../../axios-order";

const EditAffiliationData = ({form, afterSuccess, tickerList}) => {
    const [affiliationInfoForm] = Form.useForm();

    useEffect(() => {
        affiliationInfoForm.setFieldsValue(form);
    }, [form]);

    const onSubmit = async (values) => {
        console.log(form, values);
        try {
            const {
                data: {
                    code,
                    message: restMessage
                }
                // super bad method to update. need to fix this
            } = await axiosOrder.post("/company-info/update-affiliation", {oldData: form, newData: values}, {
                params: {
                    Identifier: values.Identifier
                }
            });
            if (code === 200) {
                message.success(restMessage);
                affiliationInfoForm.resetFields();
                await afterSuccess();
            } else throw new Error();
        } catch (e) {
            console.log(e);
            message.warning("Cannot update Affiliation identifier");
        }
    }
    return (
        <div>
            <Form layout={'vertical'} form={affiliationInfoForm} onFinish={onSubmit}>
                <Row gutter={[16, 16]}>
                    <Col md={12}>
                        <Form.Item label={'Identifier'} name={'Identifier'} rules={[{
                            required: true,
                            message: "This Is Required Field"
                        }]}>
                            <Input placeholder={"Enter Identifier Name"} disabled/>
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label={'Name'} name={'Name'}>
                            <Input placeholder={"Enter User Name"}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col md={12}>
                        <Form.Item label={'Affiliated Company'} name={'AffiliatedCompany'}>
                            <Select style={{width: '100%'}} showSearch
                                    placeholder={"Select Company From Select Box"}>
                                {tickerList.map(item => <Select.Option key={item.StockName}
                                                                       value={item.StockName}>{item.StockName}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label={'Position'} name={'Position'}>
                            <Input placeholder={"Enter Position Name"}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Button type={'primary'} htmlType={'submit'}>Update</Button>
            </Form>
        </div>
    );
};

EditAffiliationData.propTypes = {
    form: PropTypes.shape({
        AffiliatedCompany: PropTypes.string,
        Name: PropTypes.string,
        Position: PropTypes.string,
        Identifier: PropTypes.string,
    }),
    afterSuccess: PropTypes.func.isRequired,
    tickerList: PropTypes.array.isRequired
};

export default EditAffiliationData;
