import styled from "styled-components";


export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

`

export const List = styled.div`
  display: flex;

`

export const ProcessName = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #1e2329;
  letter-spacing: 0;
`
export const Name = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 30%;

`

export const ButtonContainer = styled.div`
  display: flex;
  flex-basis: 30%;
`
