import React, {useState, useEffect} from 'react';
import {Typography} from "antd";
import {EditableListProps} from "./Types";
import EditableItem from "./EditableItem";

import "./index.css";

export interface EditableType {
    AffiliatedCompany: string,
    Position: string
}

const {Title} = Typography;

const Index = ({deletable, title, editable, onChange, bordered, tickerList}: EditableListProps<EditableType[]>) => {
    const [list, setList] = useState<EditableType[]>( []);

    const onListUpdate = (listData: EditableType[]) => {
        setList(listData);
        const filteredListData = listData.filter(item => item.AffiliatedCompany.trim().length !== 0);
        onChange(filteredListData);
    }

    const onInnerChange = (text: string, index: number, type: string) => {
        const listData = [...list];
        listData[index][type] = text;
        onListUpdate(listData);
    };
    const onDeleteChange = (index: number) => {
        const listData = [...list];
        listData.splice(index, 1);
        onListUpdate(listData);
    }

    useEffect(() => {
        if (list.length === 0) {
            setList([{AffiliatedCompany: '', Position: ''}]);
        }

    }, [list]);

    const onAddNewInput = () => {
        const listData = [...list];
        listData.push({AffiliatedCompany: '', Position: ''});
        onListUpdate(listData);

    };

    return (
        <div className={'list-container'}>
            {title && (typeof title === 'string' ?
                <Title level={5}>{title}</Title>
                : title)}
            <div>
                {list.map((item, index) => {
                    return <EditableItem bordered={bordered}
                                         key={index}
                                         list={list}
                                         editable={editable}
                                         onAdd={onAddNewInput}
                                         deletable={deletable}
                                         stockList={tickerList}
                                         onChange={onInnerChange}
                                         value={item}
                                         index={index}
                                         onDelete={onDeleteChange}/>
                })}
            </div>
        </div>
    );
};


export default Index;
