import { Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import "../styles/processVariableInputField.css";

const ProcessVariableInputField = (props) => {
  const [showInput, setShowInput] = useState([]);

  let processVarArr = [{}];

  const createInputField = () => {
    setShowInput([...showInput, { id: Date.now() }]);
  };

  if (props.returnProcessVariables) {
    processVarArr = props.returnProcessVariables(showInput);
  }
  const handleModalChange = (id, key, value) => {
    let updatedShowInput = showInput.map((si) => {
      if (si.id === id) {
        let obj = { ...si };
        obj[key] = value;
        return obj;
      }

      return si;
    });
    setShowInput(updatedShowInput);
  };

  return (
    <>
      {" "}
      <FontAwesomeIcon
        icon={faPlus}
        size="2xl"
        cursor="pointer"
        onClick={createInputField}
      />
      {showInput.map((field) => (
        <div className="wrapperForProcessVariableInputField">
          <SubProcessVariableInputField
            key={`sub-input-field-${field.id}`}
            id={field.id}
            handleModalChange={handleModalChange}
          />
        </div>
      ))}
    </>
  );
};

const SubProcessVariableInputField = (props) => {
  const [modalValue, setModalValue] = useState();
  const [secondModalValue, setSecondModalValue] = useState();

  return (
    <>
      <div className="top">
        <Input value="name" id={props.id} />
        <p style={{ fontWeight: "bold" }}>:</p>
        <Input
          value={modalValue}
          onChange={(event) => {
            setModalValue(event.target.value);
            props.handleModalChange(props.id, "name", event.target.value);
          }}
        />
      </div>

      <div className="bot">
        <Input value="displayName" id={props.id} />
        <p style={{ fontWeight: "bold" }}> :</p>
        <Input
          value={secondModalValue}
          onChange={(event) => {
            setSecondModalValue(event.target.value);
            props.handleModalChange(
              props.id,
              "displayName",
              event.target.value
            );
          }}
        />
      </div>
    </>
  );
};

export default ProcessVariableInputField;
