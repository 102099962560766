// src/CardLink.js
import React from 'react';
import { Card } from 'antd';
import { Link } from 'react-router-dom';

const CardLink = ({ title, description, linkTo }) => {
    return (
        <Link to={linkTo}>
            <Card title={title} style={{ width: 300, margin: 16 }}>
                <p>{description}</p>
            </Card>
        </Link>
    );
};

export default CardLink;
