import moment from "moment";
import { Form, DatePicker, Select, Input, Space, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import axiosOrder from "axios-order";
import { useHistory } from "react-router-dom";
import { getHeader } from "methods/actions";
import { Button } from "react-bootstrap";

import MaterialAppBar from "components/MaterialAppBar";
import "./financialUpdates.css";

const current_date = moment();

const useForm = () => {
  const [form] = Form.useForm();
  return form;
};

const MutualFunds = () => {
  const form = useForm();
  const [latestFy, setLatestFy] = useState("");
  const [selectedTkr, setSelectedTkr] = useState({});
  const [tkr, setTkr] = useState([]);
  const [detailsList, setDetailsList] = useState([]);
  // const [companyName, setCompanyName] = useState([]);
  let history = useHistory();

  const header = getHeader();

  const handleChange = (value) => {
    setSelectedTkr(value);
  };

  const displayAddUpdates = (event) => {
    event.preventDefault();
    history.push("/addUpdate");
  };

  const monthlyDetailsList = [
    {
      label: "Monthly Financial Report -Shrawan ",
      value: "Monthly Financial Report -Shrawan ",
    },
    {
      label: "Monthly Financial Report -Bhadra ",
      value: "Monthly Financial Report -Bhadra ",
    },
    {
      label: "Monthly Financial Report -Ashwin ",
      value: "Monthly Financial Report -Ashwin ",
    },
    {
      label: "Monthly Financial Report -Kartik ",
      value: "Monthly Financial Report -Kartik ",
    },
    {
      label: "Monthly Financial Report -Mangsir ",
      value: "Monthly Financial Report -Mangsir ",
    },
    {
      label: "Monthly Financial Report -Poush ",
      value: "Monthly Financial Report -Poush ",
    },
    {
      label: "Monthly Financial Report -Magh ",
      value: "Monthly Financial Report -Magh ",
    },
    {
      label: "Monthly Financial Report -Falgun ",
      value: "Monthly Financial Report -Falgun ",
    },
    {
      label: "Monthly Financial Report -Chaitra ",
      value: "Monthly Financial Report -Chaitra ",
    },
    {
      label: "Monthly Financial Report -Baishakh ",
      value: "Monthly Financial Report -Baishakh ",
    },
    {
      label: "Monthly Financial Report -Jestha ",
      value: "Monthly Financial Report -Jestha ",
    },
    {
      label: "Monthly Financial Report -Ashad ",
      value: "Monthly Financial Report -Ashad ",
    },
  ];

  useEffect(() => {
    getSectorStockSymbol();
    getCurrentFiscalYear();
  }, []);

  useEffect(() => {
    form.setFieldValue("FiscalYear", latestFy);
  }, [latestFy]);

  const getSectorStockSymbol = () => {
    axiosOrder
      .get(`/company-info/stock-symbol/sector?sector=MF`, header)
      .then((response) => {
        setTkr(response.data.message);
      })
      .catch((e) => {
        console.log("error--", e);
      });
  };

  const getCurrentFiscalYear = () => {
    axiosOrder
      .get(`/fiscal-year/current`, header)
      .then((response) => {
        setLatestFy(response.data.message.englishFiscalYear);
        appendDetailList(
          response.data.message.initialNepaliYear,
          response.data.message.finalNepaliYear
        );
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const appendDetailList = (initialNepaliYear, finalNepaliYear) => {
    const yearlyDetails = monthlyDetailsList.map((detail, index) => {
      if (index <= 8) {
        detail.label = detail.label + initialNepaliYear;
        detail.value = detail.value + initialNepaliYear;
      } else {
        detail.label = detail.label + finalNepaliYear;
        detail.value = detail.value + finalNepaliYear;
      }
      return detail;
    });

    setDetailsList(yearlyDetails);
  };

  const onFinish = (values) => {
    let inputDate = moment().format("YYYY-MM-DD");
    if (values.InputDate) {
      inputDate = values.InputDate.format("YYYY-MM-DD");
    }

    if (selectedTkr.length === 0) {
      console.log("Please select at least one ticker symbol.");
      return; // Do not proceed with form submission
    }

    selectedTkr.forEach((item) => {
      const tkrCompany = tkr.find((a) => {
        return a.ticker === item;
      });

      const form_values = {
        ...values,
        TKR: item,
        Company: tkrCompany.StockName ? tkrCompany.StockName : null,
        Time: null,
        InputDate: inputDate ? inputDate : null,
        EventDate: inputDate ? inputDate : null,
        OpenDate: null,
        CloseDate: null,
        BookCloseDate: null,
        AllotmentDate: null,
        DetailsType: values.DetailsType ? values.DetailsType : null,
        Details: values.DetailsType ? values.DetailsType : null,
        Agenda: values.DetailsType ? values.DetailsType : null,
        InputType: "Financials",
        LastDayNameTransfer: null,
        ShareRegistrar: null,
        Venue: null,

        // Agenda: detailAuto.Agenda ? detailAuto.Agenda : null,
      };

      axiosOrder
        .post("/updates/add-update", form_values, header)
        .then(function (response) {
          console.log("response", response);
          if (response.data.code === 200) {
            console.log(
              "Successfully added financial updates.",
              response.config.data
            );
            message.success(response.data.message);
            form.resetFields();
          } else {
            console.error(
              "Failed to add financial updates.",
              response.config.data
            );
            message.error(response.data.message);
          }
        })
        .catch(function (error) {
          console.error("Failed to add financial updates.", error);
          message.error("Failed to add financial updates.");
        });
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <MaterialAppBar />
      <div className="wrapperNavMutual">
        <FontAwesomeIcon
          icon={faLeftLong}
          size="2xl"
          onClick={displayAddUpdates}
          className="leftLongIcon"
        />
        <h3 className="headingNav"> Mutual Funds</h3>
      </div>

      <Form
        name="Mutual Funds"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{
          FiscalYear: latestFy,
        }}
      >
        <div className="rows">
          <div className="firstRow">
            <Form.Item label="InputDate" name="InputDate">
              <DatePicker
                defaultValue={current_date}
                format="YYYY-MM-DD"
                value={current_date}
              />
            </Form.Item>
            <Form.Item label="FiscalYear" name="FiscalYear">
              <Input disabled />
            </Form.Item>

            <Form.Item label="DetailsType" name="DetailsType">
              <Select options={detailsList} style={{ width: "320px" }}></Select>
            </Form.Item>
          </div>

          <div className="secondRow">
            <Form.Item label="TKR" name="TKR">
              <Space
                style={{
                  width: "600px",
                }}
                direction="vertical"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select"
                  onChange={handleChange}
                  options={
                    tkr.map((a) => {
                      return {
                        label: a.ticker,
                        value: a.ticker,
                      };
                    }) || null
                  }
                />
              </Space>
            </Form.Item>
          </div>
        </div>

        <div className="submitButton">
          <Form.Item>
            <Button type="primary" htmlType="submit" className="button">
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default MutualFunds;
