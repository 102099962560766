import { Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import "../styles/customInputField.css";

const CustomInputField = (props) => {
  const [showInput, setShowInput] = useState([]);
  const [modal, setModal] = useState({});

  let returnEventStatement = null;
  let returnUserTaskStatement = null;

  if (props.returnEventInputObj) {
    returnEventStatement = props.returnEventInputObj(modal);
  }
  if (props.returnUserTaskInputObj) {
    returnUserTaskStatement = props.returnUserTaskInputObj(modal);
  }

  const createInputField = () => {
    setShowInput([...showInput, { id: Date.now() }]);
  };

  const handleModalChange = (id, key, value) => {
    let updatedShowInput = showInput.map((si) => {
      if (si.id === id) {
        return {
          id: si.id,
          key: key,
          value: value,
        };
      }

      return si;
    });
    setShowInput(updatedShowInput);
  };

  useEffect(() => {
    let updateModal = {};

    showInput.map((si) => {
      if (si.key) {
        updateModal[si.key] = si.value;
      }
    });

    setModal(updateModal);
  }, [showInput]);

  console.log("sad >>>asd  ", modal);

  return (
    <>
      <FontAwesomeIcon
        icon={faPlus}
        size="2xl"
        cursor="pointer"
        onClick={createInputField}
      />

      {showInput.map((field) => (
        <div className="wrapperForCustomInputField">
          <SubInputField
            key={`sub-input-field-${field.id}`}
            id={field.id}
            handleModalChange={handleModalChange}
          />
        </div>
      ))}
      {returnEventStatement}
      {returnUserTaskStatement}
    </>
  );
};

const SubInputField = (props) => {
  const [modalKey, setModalKey] = useState();
  const [modalValue, setModalValue] = useState();

  return (
    <>
      <Input
        value={modalKey}
        onChange={(event) => {
          setModalKey(event.target.value);
          props.handleModalChange(props.id, event.target.value, modalValue);
        }}
      />
      <p style={{ fontWeight: "bold" }}>:</p>
      <Input
        value={modalValue}
        onChange={(event) => {
          setModalValue(event.target.value);
          props.handleModalChange(props.id, modalKey, event.target.value);
        }}
      />
    </>
  );
};

export default CustomInputField;
