import { Form } from "antd";
import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { BSToAD } from "bikram-sambat-js";
import { CalendarOutlined } from "@ant-design/icons";
import { NepaliDatePicker } from "datepicker-nepali-reactjs";

import "./addUpdate.css";

const BSDatePicker = (props) => {
  const handleEventDateChange = (val) => {
    if (val) {
      props.setDate({
        nepali: val,
        english: BSToAD(val),
      });
    } else {
      props.setDate({
        ...props.date,
        nepali: val,
        // editable: val,
      });
    }
  };

  // const deprioritizeCalendar = () => {
  //   const varNepaliDate = document.querySelector(
  //     `#${props.id} .nepali-datepicker`
  //   );
  //   varNepaliDate.click();
  // };

  // const removeNepaliDateSelect = (e) => {
  //   if (e.keyCode === 9) {
  //     return;
  //   }
  //   const keyboardNepaliDate = document.querySelector(
  //     `#${props.id} .inputClass`
  //   );
  //   if (keyboardNepaliDate) {
  //     keyboardNepaliDate.focus();
  //   }
  // };

  // const setNepaliDateKeyBoard = (e) => {
  //   const regex = /^\d{4}-\d{2}-\d{2}$/;
  //   const value = e.target.value;
  //   if (value.match(regex) !== null) {
  //     props.setDate({
  //       ...props.date,
  //       nepali: value,
  //       editable: value,
  //       english: BSToAD(value),
  //     });
  //   } else {
  //     props.setDate({
  //       ...props.date,
  //       editable: value,
  //     });
  //   }
  // };

  const resetAction = () => {
    props.setDate({ nepali: "", english: "" });
  };
  const resetButtonRef = useRef(null);
  useEffect(() => {
    resetButtonRef.current = document.querySelector(
      `#${props.id} #resetDateButton`
    );

    const handleResetClick = () => {
      resetAction();
    };

    if (resetButtonRef.current) {
      resetButtonRef.current.addEventListener("click", handleResetClick);
    }

    return () => {
      if (resetButtonRef.current) {
        resetButtonRef.current.removeEventListener("click", handleResetClick);
      }
    };
  }, [props.id]);

  return (
    <div id={props.id} className="bs-date-picker">
      <div className="wrapperForInputDiv">
        <div className="wrapperLabel">
          <label
            className="dateLabel"
            rules={[
              {
                required: true,
                message: "Please input share registrar!",
              },
            ]}
          >
            {props.label}
          </label>
        </div>
        <div className="wrapperForCalendarAndNdp">
          {/* <input
            id="bsDateInput"
            className="inputClass"
            type="text"
            placeholder="yyyy-mm-dd"
            autoComplete="off"
            onClick={() => deprioritizeCalendar()}
            value={props.date?.editable}
            // onChange={(value) => setNepaliDateKeyBoard(value)}
          /> */}
          <Form.Item>
            <NepaliDatePicker
              placeholder="YYYY-MM-DD"
              defaultDate={props.date?.nepali}
              value={props.date?.nepali}
              onDateSelect={handleEventDateChange}
              showResetDateButton={true}
            />
          </Form.Item>
          <CalendarOutlined
            style={{ cursor: "pointer" }}
            className="calendar"
          />

          <input
            type="text"
            value={props.date.english ? props.date.english : ""}
            className="englishInputClass"
          />
        </div>
      </div>

      {/* <div onKeyDown={(e) => removeNepaliDateSelect(e)}> */}

      {/* </div> */}
    </div>
  );
};

BSDatePicker.propsTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  date: PropTypes.any,
  setDate: PropTypes.func,
};

export default BSDatePicker;
