import {Table, Select, Button, InputNumber, DatePicker, message} from 'antd';
import '../style.css';
import {useEffect, useRef, useState} from 'react';

const SavingAccountTable = ({tableData, postData}) => {

    const [dataSource, setDataSource] = useState([]);
    const [allInputData, setAllInputData] = useState(null);
    const dateRef = useRef(null);

    useEffect(() => {
        setDataSource(tableData);
    }, [tableData]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'productName',
            key: 'productName',
            // sorter: (a, b) => a.productName.localeCompare(b.productName),
        }, {
            title: 'Min Balance',
            dataIndex: 'minBalance',
            key: 'minBalance',
            render: (text, record) => (
                <InputNumber value={text} onChange={(value) => onMinBalanceChange(value, record)}/>
            ),
        }, {
            title: 'Interest Rate',
            dataIndex: 'interestRate',
            key: 'interestRate',
            render: (text, record) => (
                <InputNumber value={text} onChange={(value) => onInterestChange(value, record)}/>
            ),
        },
    ];

    const onMinBalanceChange =( value,record) =>{
        console.log('record', record);
        const newData = [...dataSource];
        const updatedRecord = {...record, minBalance: Number(value)};
        newData.splice(record.key, 1, updatedRecord);
        setDataSource(newData);
    }

    const onInterestChange = (value, record) => {
        console.log('record', record);
        const newData = [...dataSource];
        const updatedRecord = {...record, interestRate: Number(value)};
        newData.splice(record.key, 1, updatedRecord);
        setDataSource(newData);
    }

    const onSubmit = () => {
        postData(dataSource);
    };
    const onAllInputChange = (value) => {
        console.log('value', value);
        setAllInputData(value);
    }
    const fillAllInputValue = () => {
        console.log('inside button dataSource', dataSource);
        const newData = [...dataSource];
        newData.forEach(item => {
            item.interestRate = allInputData;
        })
        setDataSource(newData);
    }

    return (
        <>
            <div>
                <InputNumber placeholder="Add to all" onChange={onAllInputChange}/>
                <Button type={'primary'} onClick={fillAllInputValue}>Add to All</Button>
            </div>
            <Table dataSource={dataSource} columns={columns} scroll={{y: 450}} pagination={false}/>
            <div className='d-flex justify-content-end' style={{marginTop: '2rem'}}>
                <Button onClick={onSubmit} type={'primary'} style={{background: '#2D3648'}}> Save</Button>
            </div>
        </>
    );
};

export default SavingAccountTable;
