import {Table, Button, Popconfirm, Form, Input, Select} from 'antd';
import React, {useEffect, useState, useRef, useContext} from 'react';
import './style.css';
import {getHeader} from "../../../../methods/actions";
import GenericSearch from "../../../GenericSearch";

const CustomTable = (props) => {
    const {datas, options, search = false, postData} = props;
    const {editable, actionColumns, pagination, hideColumns} = options || {};
    const [columns, setColumns] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [editableColumns, setEditableColumns] = useState([]);
    const [selectColumns, setSelectColumns] = useState([]);
    const [selectOptions, setSelectOptions] = useState([]);
    const [paginationValue, setPaginaitonValue] = useState(false);
    const [hide, setHide] = useState([]);

    const header = getHeader();
    useEffect(() => {
        setDataSource(datas);
    }, [datas]);

    useEffect(() => {
        if (dataSource.length > 0) {
            console.log("datas",dataSource);
            loadData();
        }
    }, [dataSource]);

    useEffect(() => {
        if (editable !== undefined) {
            if (editable.input !== undefined) {
                setEditableColumns(editable.input.columns);
            }
            if (editable.select !== undefined) {
                setSelectColumns(editable.select.columns);
                setSelectOptions(editable.select.option);
            }
        }
        if (pagination !== undefined) {
            setPaginaitonValue(pagination);
        }
        if (hideColumns !== undefined) {
            setHide(hideColumns);
        }
    }, [editable]);

    const onInputChange = (key, index) => (e) => {
        const newData = [...dataSource];
        newData[index][key] = e.target.value;
        setDataSource(newData);
    };

    const onSelectChange = (key, index) => (e) => {
        const newData = [...dataSource];
        newData[index][key] = e;
        setDataSource(newData);
    };

    const loadData = () => {
        const firstObject = dataSource[0] || {};
        const cols = [];

        for (const item in firstObject) {
            let render = (value) => {
                return <span>{String(value)}</span>;
            };

            const sorter = (a, b) => {
                if (typeof firstObject[item] === 'string') {
                    return a[item].localeCompare(b[item]);
                }
                return a[item] - b[item];
            };
            if (typeof firstObject[item] === 'object') {
                render = (value) => {
                    return (
                        <span>
                             {value !== undefined &&
                                 Object.keys(value).map(key => (
                                     <>
                                         {String(key).charAt(0).toUpperCase() + String(key).slice(1)}: {value[key]}
                                     </>
                                 ))
                             }
                        </span>
                    );
                };
            }
            if (hide.includes(item.toLowerCase()) || item.toLowerCase() === 'key') {
                continue;
            }

            if (editableColumns.includes(item)) {
                const col = {
                    title: String(item).charAt(0).toUpperCase() + String(item).slice(1),
                    dataIndex: item,
                    key: item,
                    // sorter: sorter,
                    render: (text, record, index) => (
                        <Input defaultValue={text} onChange={onInputChange(item, index)}/>
                    ),
                };

                cols.push(col);
            } else if (selectColumns.includes(item)) {
                console.log('selectColumns', selectColumns, item);
                const col = {
                    title: String(item).charAt(0).toUpperCase() + String(item).slice(1),
                    dataIndex: item,
                    key: item,
                    // sorter: sorter,
                    render: (text, record, index) => (
                        <Select options={selectOptions} defaultValue={text} onChange={onSelectChange(item, index)}/>
                    ),
                };
                cols.push(col);
            } else {
                const col = {
                    title: String(item).charAt(0).toUpperCase() + String(item).slice(1),
                    dataIndex: item,
                    key: item,
                    // sorter: sorter,
                    render: render,
                };

                cols.push(col);
            }
        }

        if (actionColumns) {
            const actionCol = {
                dataIndex: 'action',
                render: (_, record) => {
                    // dataSource.length >= 1 ?
                    return {
                        props: {
                            className: 'flex justify-evenly min-w-fit',
                        },
                        children: Object.keys(actionColumns).map((item) =>
                            (<>
                                {item.toLowerCase() === 'delete' ?
                                    <Popconfirm title='Sure to delete?'
                                                onConfirm={() => actionColumns[item].method(record.key)}>
                                        <a>{actionColumns[item].icon}</a>
                                    </Popconfirm> :
                                    <a onClick={() => actionColumns[item].method(record.key)}>{actionColumns[item].icon}</a>}
                            </>),
                        ),
                        // : null,
                    };
                },
            };
            cols.push(actionCol);
        }

        setColumns(cols);
    };

    const onSubmit = () => {
        try {
            console.log('finishData', dataSource);
            postData(dataSource);
        } catch (e) {
            console.log(e);
        }
    };

    if (dataSource.length === 0) {
        return (
            <>
                NO DATA PRESENT
            </>
        );
    }

    return (
        <>
            <div className={'simpleTable'}>
                {search ?
                  <GenericSearch mainData={dataSource} setFilteredData={setFilteredData}
                                setSearchLoading={setSearchLoading} /> : null
                }
                <Table
                    dataSource={searchLoading ? filteredData : dataSource}
                    columns={columns} sortDirections={['ascend', 'descend']}
                    pagination={paginationValue}
                    scroll={{y: 350}}/>
                {editable !== undefined ?
                    <div className={editable.previous ?'flex justify-between':'d-flex justify-content-end'} style={{marginTop: '2rem'}}>
                        {editable.previous &&
                            <Button onClick={editable.previous}>Cancel</Button>}
                        <Button onClick={onSubmit} type={'primary'} style={{background: '#2D3648'}}> Save</Button>
                    </div> : null
                }
            </div>
        </>
    );
};

export default CustomTable;
