import ExampleTheme from "./themes/ExampleTheme";
import {LexicalComposer} from "@lexical/react/LexicalComposer";
import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin";
import {ContentEditable} from "@lexical/react/LexicalContentEditable";
import {HistoryPlugin} from "@lexical/react/LexicalHistoryPlugin";
import {AutoFocusPlugin} from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import {HeadingNode, QuoteNode} from "@lexical/rich-text";
import {TableCellNode, TableNode, TableRowNode} from "@lexical/table";
import {ListItemNode, ListNode} from "@lexical/list";
import {CodeHighlightNode, CodeNode} from "@lexical/code";
import {AutoLinkNode, LinkNode} from "@lexical/link";
import {LinkPlugin} from "@lexical/react/LexicalLinkPlugin";
import {ListPlugin} from "@lexical/react/LexicalListPlugin";
import {MarkdownShortcutPlugin} from "@lexical/react/LexicalMarkdownShortcutPlugin";
import {TRANSFORMERS} from "@lexical/markdown";

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import {OnChangePlugin} from "@lexical/react/LexicalOnChangePlugin";
import {$getRoot, $getSelection, $insertNodes} from "lexical";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {useEffect, useState} from "react";
import {$generateHtmlFromNodes} from "@lexical/html";
import {Button} from "antd";
import {$generateNodesFromDOM} from "@lexical/html";

function Placeholder() {
    return <div className="editor-placeholder">Enter blog contents here...</div>;
}

const editorConfig = {
    // The editor theme
    theme: ExampleTheme,
    // Handling of errors during update
    onError(error) {
        throw error;
    },
    // Any custom nodes go here
    nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode,
    ],
};

function handleTextAreaChange(editor, setBlogContent) {
    // editor.registerTextContentListener((editorState) => {
    //   editor.update(() => {
    //     const htmlString = $generateHtmlFromNodes(editor, null);
    //     setBlogContent(htmlString);
    //   });
    // });
    editor.update(() => {
        const htmlString = $generateHtmlFromNodes(editor, null);
        setBlogContent((prevProp) => {
            return {
                ...prevProp,
                blogContent: htmlString,
            };
        });
    });
}

export default function Editor({className, selectedEvent, setBlogContent}) {
    const initialConfig = {
        namespace: 'MyEditor',
        theme: ExampleTheme,
        onError: (error) => {
            throw error;
        },
    };
    return (
        <div className={`text-editor`}>
            <LexicalComposer initialConfig={editorConfig}>
                <div className={`editor-container border  ${className}`}>
                    <ToolbarPlugin />
                    <div className="editor-inner">
                        <RichTextPlugin
                            contentEditable={<ContentEditable className="editor-input" />}
                            placeholder={<Placeholder />}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                        <HistoryPlugin />
                        <AutoFocusPlugin />
                        <CodeHighlightPlugin />
                        <ListPlugin />
                        <LinkPlugin />
                        <OnChangePlugin
                            onChange={(editorState, editor) => {
                                // console.log(editor);
                                handleTextAreaChange(editor, setBlogContent);
                                editorState.read(() => {
                                    // Read the contents of the EditorState here.
                                    const root = $getRoot();
                                    const selection = $getSelection();

                                    // console.log(root, selection);
                                });
                            }}
                        />

                        <AutoLinkPlugin />
                        <ListMaxIndentLevelPlugin maxDepth={7} />
                        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                    </div>
                </div>
                <MyCustomAutoFocusPlugin
                    setBlogContent={setBlogContent}
                    selectedEvent={selectedEvent}
                />
            </LexicalComposer>
        </div>
    );
}

function MyCustomAutoFocusPlugin({setBlogContent, selectedEvent}) {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        // if (Object.keys(selectedEvent).length !== 0) {
        //     editor.update(() => {
        //         $getRoot().clear();
        //         const parser = new DOMParser();
        //         const dom = parser.parseFromString(
        //             selectedEvent.blogContent,
        //             "text/html"
        //         );
        //
        //         const nodes = $generateNodesFromDOM(editor, dom);
        //
        //         $getRoot().select();
        //
        //         $insertNodes(nodes);
        //     });
        // }

        editor.focus();
    }, []);

    return (
        <>
            <div className="mt-4 flex justify-end">
                <Button type="primary" onClick={() => {
                }} htmlType="submit">
                    <span className="px-6">Save</span>
                </Button>
            </div>
        </>
    );
}