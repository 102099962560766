/* eslint-disable no-fallthrough */
import React, { useEffect } from 'react'
// import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap'
import axios from '../axios-order';
import CustomizedSnackbars from './CustomizedSnackbars'
import { useHistory } from 'react-router-dom';
// import { makeStyles } from '@material-ui/core/styles';

import { getHeader } from '../methods/actions'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: "auto",
  }
};
const inputStyle = {
  margin: '5px',
  padding: '2px',
  display: 'flex',
  flexDirection: 'row'

}
// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));


function SystemXUpdateUserModel(props) {
  let history = useHistory();
  const header = getHeader();
  // let { username, password, groupT, machineId } = props.row;
  // const classes = useStyles();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [showAlert, setshowAlert] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [response, setresponse] = React.useState(false);
  const [responseData, setresponseData] = React.useState(false);
  const [username, setusername] = React.useState(props.row.username);
  const [password, setpassword] = React.useState(props.row.password);
  const [groupT, setGroupT] = React.useState(props.row.groupT);
  const [uniqueness, setUniqueness] = React.useState(props.row.uniqueness);
  const [payment, setPayment] = React.useState(props.row.payment);

  useEffect(() => {
    setusername(props.row.username);
    setpassword(props.row.password);
    setGroupT(props.row.groupT);
    setUniqueness(props.row.uniqueness);
    setPayment(props.row.payment);
  }, [
    props.row.username,
    props.row.password,
    props.row.groupT,
    props.row.uniqueness,
    props.row.payment
  ])

  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setresponse(false);
    setSubmitting(false);
    setIsOpen(false);
    props.fetchAllDataByOption(props.database);
  }
  const logout = () => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('user');
    history.push('/login');
  }
  const onUsernameChangeHandler = (event) => {
    event.preventDefault();
    setusername(event.target.value);
  }
  const onPasswordChangeHandler = (event) => {
    event.preventDefault();
    setpassword(event.target.value);
  }
  const onGroupTChangeHandler = (event) => {
    event.preventDefault();
    setGroupT(event.target.value);
  }
  const onUniquenessChangeHandler = (event) => {
    event.preventDefault();
    setUniqueness(event.target.value);
  }
  const onPaymentChangeHandler = (event) => {
    event.preventDefault();
    setPayment(event.target.value);
  }
  const onModalSubmit = () => {
    let act = "changed ";
    if (username !== props.row.username) {
      act = act + "username ";
    }
    if (password !== props.row.password) {
      act = act + "password ";
    }
    if (groupT !== props.row.groupT) {
      act = act + "groupT ";
    }
    if (uniqueness !== props.row.uniqueness) {
      act = act + "uniqueness ";
    }
    if (payment !== props.row.payment) {
      act = act + "payment ";
    }
    if (act === "changed ") {
      act = ""
    } else {
      act = act + `of id ${props.row.id}`
    }
    let payload = {
      id: props.row.id,
      username: username,
      password: password,
      groupT: groupT,
      uniqueness: uniqueness,
      payment: payment,
      actions: act,
    };
    axios.post(`/user/details/systemx/update`, payload, header).then((response) => {
      // console.log(response.data);
      setresponse(response.data.success);
      setresponseData(response.data);
      setshowAlert(true);
    }).catch((e) => {
      // console.log(e.response.data.msg);
      let data = {
        "msg": e.response.data.msg
      }
      setresponseData(data);
      setshowAlert(true);
      if (e.message === 'Request failed with status code 401') {
        // console.log("logout called");
        logout();
      }
    }).finally(() => {
      setSubmitting(false);
      closeModal();
    });
  }



  return (
    <div style={customStyles}>
      <CustomizedSnackbars open={showAlert} setOpen={setshowAlert} msg={responseData.msg} severity={responseData.success} style={{ width: "200px" }} />
      <Button variant={props.variant} onClick={openModal}>{props.name}</Button>
      <Modal show={modalIsOpen}
        style={customStyles} >
        <Modal.Header style={{ display: 'flex', justifyContent: "sapace-between" }}>
          <p><b>Client :</b> <b>{props.row.username}</b></p>
          {/* <button onClick={closeModal}>close</button> */}
          <Button variant="success" onClick={closeModal}>close</Button>
        </Modal.Header>
        <Modal.Body>
          <h2 style={{ margin: 1, padding: 2, color: "red" }}>Warning!!</h2>
          <h4 style={{ margin: 1, padding: 2, color: "f00000" }}>This will alter the database. So be sure before commiting to change.</h4>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "left", border: "1px solid ", margin: "10px 10% 0px 10%", padding: "5px" }}>
            <div style={inputStyle}><label>Machine Id: </label><input type='text' className='formInput' id='uniqueness' value={uniqueness} onChange={onUniquenessChangeHandler}></input></div>
            <div style={inputStyle}><label>Username: </label><input type='text' className='formInput' id='username' value={username} onChange={onUsernameChangeHandler}></input></div>
            <div style={inputStyle}> <label>Password: </label><input type='text' className='formInput' id='password' value={password} onChange={onPasswordChangeHandler}></input></div>
            <div style={inputStyle}> <label>Group: </label><input type='text' className='formInput' id='group' value={groupT} onChange={onGroupTChangeHandler}></input></div>
            <div style={inputStyle}> <label>payment: </label>
              <select value={payment} id='payment' onChange={onPaymentChangeHandler}>
                <option value='unlimited'>unlimited</option>
                <option value='trial'>trial</option>
              </select>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer> <Button variant="warning" disabled={submitting || response} onClick={onModalSubmit}>{!(submitting || response) ? "commit" : submitting ? "Submitting..." : "Updated"}</Button>

        </Modal.Footer>
      </Modal>

    </div>
  )
}
export default SystemXUpdateUserModel