import {useEffect, useState} from "react";
import {Input} from "antd";
import {SearchOutlined, DownOutlined} from "@ant-design/icons";

const GenericSearch = (props) => {
    const {mainData, setFilteredData, setSearchLoading} = props;

    const onSearchClick = (event) => {
        const searchValue = event.target.value.trim().toLowerCase();
        if (searchValue.length === 0) {
            setSearchLoading(false);
        } else {
            setSearchLoading(true);
        }

        setFilteredData(mainData.filter((o) =>
            Object.keys(o).some((key) =>
                JSON.stringify(o[key]).toLowerCase().includes(searchValue))));

    }
    return (
        <>
            <div>
                <Input
                    allowClear
                    placeholder="Search here"
                    onChange={(event) => onSearchClick(event)}
                    prefix={<SearchOutlined/>}
                />
            </div>
        </>
    )
}

export default GenericSearch;