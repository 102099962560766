import {Select} from "antd";
import {useHistory} from "react-router-dom";
import './styles.css';

const FlowvityServices = ({defaultValue}) => {
    const router = useHistory();

    const handleChange = (value) => {
        router.push(value);
    }

    return (
        <>
            <div className={"featureFlagServices"}>
                <Select
                    defaultValue={defaultValue}
                    onChange={handleChange}
                    style={{fontSize: "28px", marginBlock: "20px"}}
                    options={
                        [
                            {
                                value: "admin-panel",
                                label: "adminPanel",
                            },
                            {
                                value: "Website",
                                label: "Website",
                            },
                            {
                                value: "bpmn-panel",
                                label: "bpmnPanel",
                            },
                            {
                                value: "appPanel",
                                label: "appPanel",
                            },
                        ]
                    }
                >
                </Select>
            </div>
        </>
    )
};

export default FlowvityServices;