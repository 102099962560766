import { Tabs } from "antd";
import FlowvityServices from "../../components/FeatureFlag/Common/FlowvityServices/FlowvityServices";
import sideBarItems from "../../components/SideBar/sideBarItems";
import SideBarLayout from "../../components/SideBar";
import FeatureFlagForm from "components/FeatureFlagForm";

const items = [
  {
    key: "1",
    label: "New company",
    children: "Content of New company",
  },
  {
    key: "2",
    label: "New feature",
    children: <FeatureFlagForm />,
  },
];

const FlowvityBpmnPanel = () => {
  return (
    <SideBarLayout siderItems={sideBarItems.featureFlag}>
      <FlowvityServices defaultValue={"bpmn-panel"} />
      <Tabs items={items} />
    </SideBarLayout>
  );
};

export default FlowvityBpmnPanel;
